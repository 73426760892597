import React from 'react';
import { green, amber } from '@material-ui/core/colors';
import { makeStyles, Theme, SnackbarContent, Icon, IconButton } from '@material-ui/core';

const variantIcon = {
  success: 'done',
  warning: 'warning',
  error: 'error',
  info: 'info',
};

const useStyles = makeStyles((theme: Theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

type CustomSnackbarProps = {
  message?: string;
  onClose?: () => void;
  variant?: 'success' | 'warning' | 'error' | 'info';
}

const CustomSnackbarContent: React.FC<CustomSnackbarProps> = ({ message, onClose, variant, ...props }) => {
  const classes = useStyles();
  const icon = variantIcon[variant || 'info'];

  return (
    <SnackbarContent
      className={classes[variant || 'info']}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classes.icon}>{icon}</Icon>
          {message}
        </span>
      }
      action={onClose ? [
        <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
          <Icon className={classes.icon}>close</Icon>
        </IconButton>,
      ] : undefined}
      {...props}
    />
  );
}

export default CustomSnackbarContent;
