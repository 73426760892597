import React, { Fragment } from 'react';
import { format } from 'react-numberinput-formatter';

export type NumberFormatProps = {
  value: number
  unit?: string
  maximumFractionDigits?: number
  minimumFractionDigits?: number
  useGrouping?: boolean
}

export const NumberFormat: React.FC<NumberFormatProps> = ({ maximumFractionDigits, minimumFractionDigits, useGrouping, value, unit }) => {
  return <Fragment>{format(value, { maximumFractionDigits, minimumFractionDigits, useGrouping })}{unit ? ' ' + unit : null}</Fragment>
}

export type CurrencyFormatProps = Omit<NumberFormatProps, 'unit' | 'maximumFractionDigits' | 'minimumFractionDigits' | 'useGrouping'>

export const CurrencyFormat: React.FC<CurrencyFormatProps> = ({ value }) => {
  return <Fragment>&euro;{format(value, { maximumFractionDigits: 2, minimumFractionDigits: 2, useGrouping: true })}</Fragment>
}
