import React, { Fragment, useState, useCallback } from 'react';
import {TextField, Grid, InputAdornment, Snackbar} from '@material-ui/core';
import ConfirmDialog, { ConfirmDialogProps } from '../ConfirmDialog';
import AddSettingDialog from './AddSettingDialog';
import SettingsTable, { Column } from './SettingsTable';
import SettingsComponent from './SettingsComponent';
import NumberFormat from 'react-number-format';
import NumericTextField from '../NumericTextField';
import { deleteAllowed, getAll, getOne, post, remove } from "../../HTTPClients/RecipeApp/recipes/strength-classes";
import CustomSnackbarContent from "../CustomSnackbarContent";

const columns: Column[] = [
  {
    name: 'code',
    title: 'Code'
  },
  {
    name: 'cubePressureStrength',
    title: 'Kubusdruksterkte (N/mm²)',
    getValue: (value: number) => <NumberFormat displayType="text" value={value} thousandSeparator="." decimalSeparator="," />
  }
]

const StrengthClasses: React.FC<{ PrevComponent: React.FC<{ SearchElement?: JSX.Element }> }> = ({ PrevComponent }) => {
  const [dialogProps, confirmDelete] = useState({ open: false } as Omit<ConfirmDialogProps, 'title' | 'content'>);
  const [filter, setFilter] = useState('');
  const [snackbar, setSnackbar] = useState(undefined as { message: string, variant: 'success' | 'warning' | 'error' | 'info' } | undefined);

  const getRows = useCallback(async () => {
    let response=await getAll();
    return response.data.data;
  },[]);

  const getRow = useCallback(async (strengthClassId: string) => {
    let response=await getOne(Number(strengthClassId));
    return response.data.data;
  },[]);

  const handleSave = useCallback(async (strengthClass: any, strengthClasses: any[] | undefined, saveCallback: (strengthClasses?: any[]) => void) => {
    if (strengthClasses && strengthClass.id) {
      post(strengthClass).then(
          function(response) {
            const index = strengthClasses.findIndex(f => f.id === strengthClass.id);
            index >= 0 && (strengthClasses[index] = strengthClass);
            saveCallback(strengthClasses);
          }
      ).catch(
          function (error) {
            if(error.response===undefined)
              console.log(error);
            else
            if(error.response.data==='not_unique') notUniqueSnackbar();
          }
      )
    } else if (strengthClasses) {
      post(strengthClass).then(
          function(response) {
            strengthClasses.push({ ...strengthClass, id: response.data.data.id });
            saveCallback(strengthClasses);
          }
      ).catch(
          function (error) {
            if(error.response===undefined)
              console.log(error);
            else
            if(error.response.data==='not_unique') notUniqueSnackbar();
          }
      )
    }
  },[]);

  function notUniqueSnackbar() {
    setSnackbar({
      variant: 'warning',
      message: 'Deze sterkteklasse bestaat al'
    })
  }

  const handleDelete = useCallback((strengthClass: any, deleteCallback: (strengthClass: any) => void) => {
    deleteAllowed(strengthClass.id).then(
        function(response) {
          if(response.data.data) {
            confirmDelete({
              open: true,
              onCancel: () => confirmDelete({ open: false }),
              onConfirm: async () => {
                remove(strengthClass.id).then(
                    function(response) {
                      deleteCallback(strengthClass);
                    }
                ).finally(
                    function() { confirmDelete({ open: false }); }
                )
              }
            });
          } else {
            noDeleteSnackbar();
            return;
          }
        }
    )
  },[]);

  function noDeleteSnackbar() {
    setSnackbar({
      variant: 'warning',
      message: 'Deze sterkteklasse mag niet verwijderd worden'
    })
  }

  const SearchElement = <TextField placeholder="Sterkteklasse zoeken..." value={filter} onChange={e => setFilter(e.target.value)} variant="outlined" margin="dense" />;

  return (
    <SettingsComponent
      PrevComponent={PrevComponent}
      SearchElement={SearchElement}
      path="/settings/strength_classes"
      getRows={getRows}
      getRow={getRow}
    >{({ rows, row, addDialogOpen, onEdit, onSave, onChange, onDelete, onCancelAdd }) => (
      <Fragment>
        {rows && <SettingsTable
          columns={columns}
          rows={rows.filter(f => f.code.toLowerCase().indexOf(filter.toLowerCase()) >= 0).sort((a, b) => a.cubePressureStrength - b.cubePressureStrength)}
          onEdit={(strengthClass: any) => strengthClass.id && onEdit(strengthClass.id)}
          onDelete={(strengthClass: any) => handleDelete(strengthClass, onDelete)}
        />}
        {!row ? null : (
          <AddSettingDialog
            title={row.id ? 'Sterkteklasse bewerken' : 'Sterkteklasse toevoegen'}
            open={addDialogOpen}
            onClose={() => onCancelAdd()}
            onSave={() => handleSave(row, rows, onSave)}
          >
            <Grid container={true} wrap="nowrap">
              <TextField
                label="Code"
                value={row.code}
                onChange={e => onChange({ ...row, code: e.target.value })}
                variant="outlined"
                margin="normal"
                fullWidth={true}
                style={{ marginRight: 8 }}
              />
              <TextField
                label="Beschrijving"
                value={row.description}
                onChange={e => onChange({ ...row, description: e.target.value })}
                variant="outlined"
                margin="normal"
                fullWidth={true}
                style={{ marginLeft: 8 }}
              />
            </Grid>
            <NumericTextField
              label="Kubusdruksterkte"
              value={row.cubePressureStrength}
              onChange={e => onChange({ ...row, cubePressureStrength: e.target.value })}
              margin="normal"
              fullWidth={true}
              InputProps={{
                endAdornment: <InputAdornment position="end">N/mm<sup>2</sup></InputAdornment>
              }}
            />
            <NumericTextField
              label="Cilinderdruksterkte"
              value={row.cilinderPressureStrength}
              onChange={e => onChange({ ...row, cilinderPressureStrength: e.target.value })}
              margin="normal"
              fullWidth={true}
              InputProps={{
                endAdornment: <InputAdornment position="end">N/mm<sup>2</sup></InputAdornment>
              }}
            />
            <NumericTextField
              label="Buigsterkte"
              value={row.bendTensileStrength}
              onChange={e => onChange({ ...row, bendTensileStrength: e.target.value })}
              margin="normal"
              fullWidth={true}
              InputProps={{
                endAdornment: <InputAdornment position="end">N/mm<sup>2</sup></InputAdornment>
              }}
            />
            <NumericTextField
              label="Aantal monsternames / m³"
              value={row.samplePerVolume}
              onChange={e => onChange({ ...row, samplePerVolume: e.target.value })}
              margin="normal"
              fullWidth={true}
              maximumFractionDigits={0}
            />
            <NumericTextField
              label="Min. aantal monsternames"
              value={row.sampleMinimum}
              onChange={e => onChange({ ...row, sampleMinimum: e.target.value })}
              margin="normal"
              fullWidth={true}
              maximumFractionDigits={0}
            />
            <NumericTextField
              label="Max. aantal monsternames"
              value={row.sampleMaximum}
              onChange={e => onChange({ ...row, sampleMaximum: e.target.value })}
              margin="normal"
              fullWidth={true}
              maximumFractionDigits={0}
            />
          </AddSettingDialog>
        )}
        <ConfirmDialog {...dialogProps} title="Sterkteklasse verwijderen" content="Weet u zeker dat u deze sterkteklasse wilt verwijderen?" />
        <Snackbar open={Boolean(snackbar)} onClose={() => setSnackbar(undefined)} autoHideDuration={6000}>
          <CustomSnackbarContent
              variant={snackbar ? snackbar.variant : undefined}
              message={snackbar ? snackbar.message : undefined}
          />
        </Snackbar>
      </Fragment>
    )}
    </SettingsComponent>
  )
}

export default StrengthClasses;
