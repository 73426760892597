import React, { useCallback, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { TextField, Paper, Theme, makeStyles } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import ChipInput from 'material-ui-chip-input';

const InputComponent = ({ label, value, values, getSuggestionValue, onDelete, fullWidth, margin, variant, ...inputProps }: any) => {
  return values ? (
    <ChipInput
      value={values.map(getSuggestionValue)}
      inputValue={value}
      label={label}
      variant={variant}
      margin={margin}
      fullWidth={fullWidth}
      onDelete={onDelete}
      InputProps={{
        ...inputProps
      }}
    />) : (
      < TextField
        label={label}
        value={value}
        variant={variant}
        margin={margin}
        fullWidth={fullWidth}
        inputProps={inputProps}
      />
    );
}

const SuggestionsContainer = ({ containerProps, children, query }: any) => {
  return (
    <Paper {...containerProps} square={true}>
      {children}
    </Paper>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1000,
    marginTop: -8,
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  }
}));

type AutocompleteProps = {
  getSuggestions: (value: string) => any[] | Promise<any[]>,
  renderSuggestion: (suggestion: any, params: Autosuggest.RenderSuggestionParams) => JSX.Element,
  getSuggestionValue: (suggestion: any) => string
  onChange: (suggestion: any) => void
  multiple?: boolean
  onDelete?: (chip: string, index: number) => void
} & Omit<TextFieldProps, 'onChange'>

const Autocomplete: React.FC<AutocompleteProps> = ({ getSuggestions, getSuggestionValue, renderSuggestion, multiple, onDelete, ...props }) => {
  const [value, setValue] = useState(multiple ? '' : props.value || '');
  const [filtered, setFiltered] = useState([] as any);
  const theme = useStyles();

  const onChange = useCallback((event, { newValue }) => setValue(newValue), []);

  const onBlur = useCallback(() => setValue(''), []);

  const onSuggestionsFetchRequested = useCallback(async ({ value }) => setFiltered(await getSuggestions(value)), [getSuggestions]);

  const onSuggestionsClearRequested = useCallback(() => setFiltered([]), []);

  const handleSuggestionSelected = useCallback((event, { suggestion }) => {
    props.onChange(suggestion);
    multiple && setValue('');
  }, [props, multiple]);

  const inputProps = {
    ...props as any,
    value,
    ...multiple && { values: props.value || [], getSuggestionValue, onDelete },
    onChange,
    onBlur
  };

  return <Autosuggest
    id="react-autosuggest-simple"
    suggestions={filtered}
    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
    onSuggestionsClearRequested={onSuggestionsClearRequested}
    onSuggestionSelected={handleSuggestionSelected}
    getSuggestionValue={getSuggestionValue}
    renderSuggestion={renderSuggestion}
    inputProps={inputProps}
    renderInputComponent={InputComponent}
    renderSuggestionsContainer={SuggestionsContainer}
    theme={theme}
  />
}

export default Autocomplete;
