import axiosHttpClient from "../axiosHttpClient";

export function post(data: any) {
	return axiosHttpClient.post('/sieve-size', data);
}

export function getAll() {
	return axiosHttpClient.get('/sieve-sizes');
}

export function getOne(id: number) {
	return axiosHttpClient.get('/sieve-size/one/'+id.toString());
}

export function deleteAllowed(id: number) {
	return axiosHttpClient.get('/sieve-size/delete-allowed/'+id.toString());
}

export function remove(id: number) {
	return axiosHttpClient.get('/sieve-size/delete/'+id.toString());
}

