import React from "react";
import ErrorView from "../views/ErrorView";
import { Typography, Button, Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import FileLockIconFrame from "../icons/FileLockIconFrame";


const errorViewProps = {
  title: "Geen toegang",
  icon: <FileLockIconFrame  style={{width: 128, height: 128}}  />,
  statusCode: "401",
};

const NotFound: React.FC = () => {
  const history = useHistory();
  return (
    <ErrorView {...errorViewProps}>
      <Typography gutterBottom={true}>
        Maakt u maar geen zorgen, er is altijd een weg terug.
      </Typography>
      <Box display="flex" alignItems="center" marginTop={2}>
        <Button
          onClick={() => history.goBack()}
          variant="contained"
          color="secondary"
          size="large"
        >
          Ga terug
        </Button>
      </Box>
    </ErrorView>
  );
};

export default NotFound;
