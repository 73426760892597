import { withStyles } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { Switch } from '@material-ui/core';

const GreenSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: green[600],
    },
    '&$checked + $track': {
      backgroundColor: green[600],
    },
  },
  checked: {},
  track: {},
})(Switch);

export default GreenSwitch;
