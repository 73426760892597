import React from 'react';
import { ResourceType } from '../../types';
import { Grid, Card, ListItem, ListItemAvatar, Avatar, ListItemText } from '@material-ui/core';
import resourceTypes from '../../resourceTypes';

const ChoseResourceType: React.FC<{ onClick: (resourceType: ResourceType) => void }> = ({ onClick }) => {
  return (
    <Grid container={true} spacing={2}>
      {Object.keys(resourceTypes).map(k => {
        const resourceType = resourceTypes[k as ResourceType];
        return (
          <Grid item={true} key={k} xs={4}>
            <Card>
              <ListItem button={true} onClick={() => onClick(k as ResourceType)}>
                <ListItemAvatar>
                  <Avatar style={{ background: resourceType.color }}>
                    <img src={resourceType.icon} alt={resourceType.title} title={resourceType.title} style={{ width: 24, height: 24 }} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={resourceType.title} />
              </ListItem>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  )
}

export default ChoseResourceType;
