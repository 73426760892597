import React, {ReactNode} from 'react';
import { makeStyles, useTheme, Grid, ButtonBase, Card, CardContent, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';

const useOptionButtonStyles = makeStyles({
  optionButton: {
    '& :hover': {
      background: green[100]
    }
  }
});

const OptionButton: React.FC<{ onClick: () => void, selected?: boolean, children?: ReactNode }> = ({ onClick, selected, children }) => {
  const theme = useTheme();
  const { optionButton } = useOptionButtonStyles();
  return (
    <Grid item={true}>
      <ButtonBase onClick={onClick} {...!selected && { className: optionButton }}>
        <Card {...selected && { style: { background: green[600], color: theme.palette.getContrastText(green[600]) } }}>
          <CardContent style={{ paddingBottom: 16, maxWidth: 180 }}>
            <Typography variant="body2" component="div">{children}</Typography>
          </CardContent>
        </Card>
      </ButtonBase>
    </Grid>
  )
}

export default OptionButton;
