import React, { Fragment, useState, useCallback } from 'react';
import { TextField, Snackbar } from '@material-ui/core';
import ConfirmDialog, { ConfirmDialogProps } from '../ConfirmDialog';
import AddSettingDialog from './AddSettingDialog';
import SettingsTable, { Column } from './SettingsTable';
import SettingsComponent from './SettingsComponent';
import CustomSnackbarContent from "../CustomSnackbarContent";
import { deleteAllowed, getAll, getOne, post, remove } from "../../HTTPClients/RecipeApp/resources/excipientEffects";

const columns: Column[] = [
  {
    name: 'name',
    title: 'Naam'
  },
];

const ExcipientEffectsList: React.FC<{ PrevComponent: React.FC<{ SearchElement?: JSX.Element }> }> = ({ PrevComponent }) => {
  const [dialogProps, confirmDelete] = useState({ open: false } as Omit<ConfirmDialogProps, 'title' | 'content'>);
  const [filter, setFilter] = useState('');
  const [snackbar, setSnackbar] = useState(undefined as { message: string, variant: 'success' | 'warning' | 'error' | 'info' } | undefined);

  const getRows = useCallback(async () => {
    let response=await getAll();
    return response.data.data;
  }, []);

  const getRow = useCallback(async (excipientEffectId: string) => {
    let response=await getOne(Number(excipientEffectId));
    return response.data.data;
  }, []);

  const handleSave = useCallback(async (excipientEffect: any, excipientEffects: any[] | undefined, saveCallback: (excipientEffects?: any[]) => void) => {
    if (excipientEffects && excipientEffect.id) {
      post(excipientEffect).then(
          function(response) {
            const index = excipientEffects.findIndex(f => f.id === excipientEffect.id);
            index >= 0 && (excipientEffects[index] = excipientEffect);
            saveCallback(excipientEffects);
          }
      ).catch(
          function (error) {
            if(error.response===undefined)
              console.log(error);
            else
              if(error.response.data==='not_unique') notUniqueSnackbar();
          }
      )
    } else if (excipientEffects) {
      post(excipientEffect).then(
          function(response) {
            excipientEffects.push({...excipientEffect, id: response.data.data.id});
            saveCallback(excipientEffects);
          }
      ).catch(
          function (error) {
            if(error.response===undefined)
              console.log(error);
            else
              if(error.response.data==='not_unique') notUniqueSnackbar();
          }
      )
    }
  },[]);

  function notUniqueSnackbar() {
    setSnackbar({
      variant: 'warning',
      message: 'Deze hulpstof werking bestaat al'
    })
  }

  const handleDelete = useCallback((excipientEffect: any, deleteCallback: (excipientEffect: any) => void) => {
    if(excipientEffect.id>0 && excipientEffect.id<6) {
      noDeleteSnackbar();
      return;
    }
    deleteAllowed(excipientEffect.id).then(
        function(response) {
          if(response.data.allowed) {
            confirmDelete({
              open: true,
              onCancel: () => confirmDelete({ open: false }),
              onConfirm: async () => {
                  remove(excipientEffect.id).then(
                      function(response) {
                          deleteCallback(excipientEffect);
                      }
                  ).finally(
                      function() { confirmDelete({ open: false }); }
                  )
              }
            });
          } else {
            noDeleteSnackbar();
            return;
          }
        }
    )
  },[]);

  function noDeleteSnackbar() {
    setSnackbar({
      variant: 'warning',
      message: 'Deze hulpstof werking mag niet verwijderd worden'
    })
  }

  const SearchElement = <TextField placeholder="Zoeken..." value={filter} onChange={e => setFilter(e.target.value)} variant="outlined" margin="dense" />;

  return (
    <SettingsComponent
      PrevComponent={PrevComponent}
      SearchElement={SearchElement}
      path="/settings/excipient_effects"
      getRows={getRows}
      getRow={getRow}
    >{({ rows, row, addDialogOpen, onEdit, onSave, onChange, onDelete, onCancelAdd }) => (
      <Fragment>
        {rows && <SettingsTable
          columns={columns}
          rows={rows.filter(f => f.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0).sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0; // Equal
          })}
          onEdit={(excipientEffect: any) => excipientEffect.id && onEdit(excipientEffect.id)}
          onDelete={(excipientEffect: any) => handleDelete(excipientEffect, onDelete)}
        />}
        {!row ? null : (
          <AddSettingDialog
            title={row.id ? 'Hulpstof werking werken' : 'Hulpstof werking toevoegen'}
            open={addDialogOpen}
            onClose={() => onCancelAdd()}
            onSave={() => handleSave(row, rows, onSave)}
          >
            <TextField
              label="Naam"
              value={row.name}
              onChange={e => onChange({ ...row, name: e.target.value })}
              variant="outlined"
              margin="normal"
              fullWidth={true}
            />
          </AddSettingDialog>
        )}
        <ConfirmDialog {...dialogProps} title="Hulpstof werking verwijderen" content="Weet u zeker dat u deze hulpstof werking wilt verwijderen?" />
        <Snackbar open={Boolean(snackbar)} onClose={() => setSnackbar(undefined)} autoHideDuration={6000}>
          <CustomSnackbarContent
              variant={snackbar ? snackbar.variant : undefined}
              message={snackbar ? snackbar.message : undefined}
          />
        </Snackbar>
      </Fragment>
    )}
    </SettingsComponent>
  )
}

export default ExcipientEffectsList;
