import axiosHttpClient from "./axiosHttpClient";

export function post(data: any) {
	return axiosHttpClient.post('/attest', data);
}

export function getAll() {
	return axiosHttpClient.get('/attests');
}

export function getOne(id: number) {
	return axiosHttpClient.get('/attest/one/'+id.toString());
}

export function remove(id: number) {
	return axiosHttpClient.get('/attest/delete/'+id.toString());
}

