import React, { useCallback, useState } from 'react';
import { Step } from './RecipeDialogContent';
import { Box, Typography } from '@material-ui/core';
import { ResourceUnion, ResourceType, Ingredient, Excipient, Extra } from '../../types';
import { format } from 'react-numberinput-formatter';
import Dialog, { useDialog } from '../Dialog';
import OptionEditButton from './OptionEditButton';
import OptionButtonsContainer from './OptionButtonsContainer';
import ChangeIngredientDialogContent from './ChangeIngredientDialogContent';
import ToggleResourceOptionButtons from './ToggleResourceOptionButtons';
import { getBinderTotal } from '../../computed/recipeComputations';
import ConfirmDialog, {ConfirmDialogProps} from "../ConfirmDialog";
import {getAll} from "../../HTTPClients/RecipeApp/resources/resources";

const excipientsExtrasStep: Step = {
  title: 'Hulpstoffen en extra\'s',
  hasErrors: recipe => false,
  isCompleted: recipe => recipe.ingredients.filter(r => [ResourceType.Excipient, ResourceType.Extra].indexOf(r.resource.type) >= 0).length > 0,
  optionalText: recipe => recipe.ingredients.filter(r => [ResourceType.Excipient, ResourceType.Extra].indexOf(r.resource.type) >= 0).map(r => r.resource.name).join(', '),
  StepComponent: ({ recipe, onChange }) => {
    const { ingredients } = recipe
    const [resources, setResources] = useState([] as ResourceUnion[])
    const { show, hide, getDialogProps } = useDialog();
    const binderTotal = getBinderTotal(ingredients, Boolean(recipe.attest))
    const [dialogProps, confirmDelete] = useState({ open: false } as Omit<ConfirmDialogProps, 'title' | 'content'>);

    const [loading, setLoading] = useState(true)
    React.useEffect(() => {
      getAll().then(
          function(response) {
            setResources(response.data.data)
            setLoading(false)
          }
      )
    },[]);

    const groups = [
      {
        options: resources.filter(r => r.active && r.type === ResourceType.Excipient && r.active && ingredients.map(i => i.resource.id).indexOf(r.id) < 0) as Excipient[],
        loadingText: 'Hulpstoffen laden'
      },
      {
        options: resources.filter(r => r.active && r.type === ResourceType.Extra && r.active && ingredients.map(i => i.resource.id).indexOf(r.id) < 0) as Extra[],
        loadingText: 'Extra\'s laden'
      }
    ];

    const selectedGroups = [
      {
        options: ingredients.filter(r => r.resource.type === ResourceType.Excipient) as Array<Ingredient & { resource: Excipient }>,
        loadingText: 'Hulpstoffen laden',
        noOptionsText: 'Geen hulpstoffen gekozen'
      },
      {
        options: ingredients.filter(r => r.resource.type === ResourceType.Extra) as Array<Ingredient & { resource: Extra }>,
        loadingText: 'Extra\'s laden',
        noOptionsText: 'Geen extra\'s gekozen'
      }
    ];

    const handleChange = useCallback((ingredient: Ingredient) => {
      const index = ingredients.findIndex(r => r.resource.id === ingredient.resource.id);
      index >= 0 && (ingredients[index] = ingredient);
      onChange({ ...recipe, ingredients: [...ingredients] });
      hide();
    }, [recipe, ingredients, hide, onChange]);

    const handleToggle = useCallback((resource: Excipient | Extra) => {
      const index=ingredients.findIndex(r => r.resource.id===resource.id);
      if(index >= 0) {
        confirmDelete({
          open: true,
          onCancel: () => confirmDelete({open: false}),
          onConfirm: () => {
            const index=ingredients.findIndex(r => r.resource.id===resource.id);
            ingredients.splice(index, 1);
            onChange({...recipe, ingredients});
            confirmDelete({open: false})
          }
        });
      } else {
        ingredients.push({resource, amount: 0} as Ingredient);
        onChange({...recipe, ingredients});
      }
    }, [recipe, ingredients, onChange]);

    const getOptionContent = useCallback((ingredient: Ingredient & { resource: Excipient | Extra }) => (
      <OptionEditButton
        onClick={() => handleToggle(ingredient.resource)}
        onEdit={() => show({ ingredient })}
        editContent={<Typography variant="subtitle2">{format(ingredient.amount, { maximumFractionDigits: 2 })} kg</Typography>}
      >
        {ingredient.resource.name}
      </OptionEditButton>
    ), [show, handleToggle]);

    return (
      <Box marginBottom={2} marginTop={1}>
        <ToggleResourceOptionButtons loading={loading} groups={groups} onToggle={handleToggle as (option: ResourceUnion) => void}>
          <OptionButtonsContainer
            loading={loading}
            groups={selectedGroups.map(group => ({ ...group, getOptionContent }))}
          />
        </ToggleResourceOptionButtons>
        <Dialog {...getDialogProps()} onEntered={(ref: HTMLElement) => ref.removeAttribute('tabindex')}>
          {(props: any) => (
            <ChangeIngredientDialogContent
              {...props}
              onChange={handleChange}
              binderTotal={binderTotal}
            />
          )}
        </Dialog>
        <ConfirmDialog {...dialogProps} title="Hulpstof verwijderen" content="Weet u zeker dat u deze hulpstof wilt verwijderen?" />
      </Box>
    )
  }
}

export default excipientsExtrasStep
