import axiosHttpClient from "../axiosHttpClient";

export function post(data: any) {
	return axiosHttpClient.post('/excipient-effect', data);
}

export function getAll() {
	return axiosHttpClient.get('/excipient-effects');
}

export function getOne(id: number) {
	return axiosHttpClient.get('/excipient-effect/one/'+id.toString());
}

export function deleteAllowed(id: number) {
	return axiosHttpClient.get('/excipient-effect/delete-allowed/'+id.toString());
}

export function remove(id: number) {
	return axiosHttpClient.get('/excipient-effect/delete/'+id.toString());
}

