import axiosHttpClient from "../axiosHttpClient";

export function post(data: any) {
	return axiosHttpClient.post('/family', data);
}

export function remove(id: number) {
	return axiosHttpClient.get('/family/delete/'+id.toString());
}

export function getAll() {
	return axiosHttpClient.get('/families');
}

export function getOne(id: number) {
	return axiosHttpClient.get('/family/one/'+id.toString());
}

export function deleteAllowed(id: number) {
	return axiosHttpClient.get('/family/delete-allowed/'+id.toString());
}
