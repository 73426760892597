import React, { useState } from 'react';
import { Dialog as MUIDialog, Toolbar, Typography, IconButton, Icon, Slide } from '@material-ui/core';
import { DialogProps as MUIDialogProps } from '@material-ui/core/Dialog';
import {TransitionProps} from "@material-ui/core/transitions/transition";

export const useDialog = () => {
  const [open, setOpen] = useState(false);
  const [additionalProps, setAdditionalProps] = useState({});

  function show(additionalProps?: any) {
    setOpen(true);
    setAdditionalProps(additionalProps);
  }

  function hide() {
    setOpen(false);
    setAdditionalProps({});
  }

  const getDialogProps = (props = {}) => ({
    ...props,
    ...additionalProps,
    open,
    onClose: hide
  });

  return { show, hide, getDialogProps }
}

export const DialogHeader: React.FC<{ children, onClose?: (e: any) => void }> = ({ children, onClose }) => (
  <Toolbar>
    <Typography variant="h6">{children}</Typography>
    <span style={{ flex: 1 }} />
    {onClose ? (
      <IconButton aria-label="Close" color="inherit" onClick={e => onClose && onClose(e)}>
        <Icon>close</Icon>
      </IconButton>
    ) : null}
  </Toolbar>
)

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props:any, ref) {
  return <Slide direction="up" {...props} />;
});
type DialogProps = MUIDialogProps & { children: (props: MUIDialogProps & any) => React.ReactNode } & any;

const Dialog: React.FC<DialogProps> = ({ children, ...props }) => {
  return (
    <MUIDialog {...props} TransitionComponent={Transition}>
      {children(props)}
    </MUIDialog>
  )
}

export default Dialog;
