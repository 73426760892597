import {Settings} from "../../config/settings";
const axios = require('axios').default;

const axiosClient = axios.create({
	baseURL: Settings.URLs.planningApp.recipeApp.root,
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		'company': Settings.company,
		'authentication': localStorage.getItem('token'),
	}
});

export default axiosClient;