import * as React from "react";

function LockBalloonIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="95" height="76">
      <g transform="translate(0.255 0.532)">
        <path
          d="M 20.424 37.709 C 20.424 17.686 36.632 1.454 56.625 1.454 C 76.619 1.454 92.826 17.686 92.826 37.709 C 92.826 57.733 76.619 73.965 56.625 73.965 C 36.632 73.965 20.424 57.733 20.424 37.709 Z"
          fill="rgb(255,182,0)"
        ></path>
        <path
          d="M 20.424 37.709 C 20.424 57.679 36.588 73.965 56.625 73.965 L 56.625 1.454 C 36.588 1.454 20.424 17.74 20.424 37.709 Z"
          fill="rgb(255,139,0)"
        ></path>
        <path
          d="M 56.625 75.418 C 35.911 75.418 18.972 58.551 18.972 37.709 C 18.972 16.964 35.814 0 56.625 0 C 77.339 0 94.278 16.867 94.278 37.709 C 94.182 58.551 77.339 75.418 56.625 75.418 Z M 56.625 2.908 C 37.46 2.908 21.876 18.515 21.876 37.709 C 21.876 56.903 37.46 72.51 56.625 72.51 C 75.79 72.51 91.374 56.903 91.374 37.709 C 91.374 18.515 75.694 2.908 56.625 2.908 Z"
          fill="rgb(51,54,58)"
        ></path>
        <path
          d="M 1.452 37.709 L 23.231 27.434 L 33.491 37.709 L 23.231 48.082 Z"
          fill="rgb(255,139,0)"
        ></path>
        <path
          d="M 23.231 49.536 C 23.037 49.536 22.844 49.536 22.65 49.439 L 0.871 39.067 C 0.387 38.873 0 38.291 0 37.709 C 0 37.128 0.29 36.643 0.871 36.352 L 22.65 25.98 C 23.328 25.592 24.199 25.98 24.586 26.659 C 24.973 27.337 24.586 28.209 23.908 28.597 L 4.84 37.612 L 23.908 46.628 C 24.586 47.015 24.973 47.791 24.586 48.566 C 24.296 49.245 23.715 49.536 23.231 49.536 Z"
          fill="rgb(51,54,58)"
        ></path>
        <path
          d="M 69.208 50.777 C 69.208 54.461 66.208 57.466 62.53 57.466 L 50.624 57.466 C 46.946 57.466 43.945 54.461 43.945 50.777 L 43.945 38.951 C 43.945 35.267 46.946 32.262 50.624 32.262 L 62.53 32.262 C 66.208 32.262 69.208 35.267 69.208 38.951 Z"
          fill="rgb(255,255,255)"
        ></path>
        <g transform="translate(42.493 17.624)">
          <path
            d="M 20.037 41.296 L 8.131 41.296 C 3.678 41.296 0 37.612 0 33.153 L 0 21.327 C 0 16.868 3.678 13.184 8.131 13.184 L 20.037 13.184 C 24.489 13.184 28.167 16.868 28.167 21.327 L 28.167 33.25 C 28.167 37.709 24.489 41.296 20.037 41.296 Z M 8.131 16.092 C 6.743 16.086 5.41 16.636 4.428 17.619 C 3.447 18.602 2.898 19.937 2.904 21.327 L 2.904 33.25 C 2.898 34.64 3.447 35.975 4.428 36.958 C 5.41 37.941 6.743 38.491 8.131 38.485 L 20.037 38.485 C 21.425 38.491 22.758 37.941 23.739 36.958 C 24.721 35.975 25.27 34.64 25.264 33.25 L 25.264 21.327 C 25.27 19.937 24.721 18.602 23.739 17.619 C 22.758 16.636 21.425 16.086 20.037 16.092 Z"
            fill="rgb(51,54,58)"
          ></path>
          <path
            d="M 4.936 16.189 C 5.711 16.189 6.388 15.51 6.388 14.735 L 6.388 10.663 C 6.388 6.398 9.873 2.908 14.132 2.908 C 18.391 2.908 21.876 6.398 21.876 10.663 L 21.876 14.735 C 21.876 15.51 22.553 16.189 23.328 16.189 C 24.102 16.189 24.78 15.51 24.78 14.735 L 24.78 10.663 C 24.78 4.75 20.037 0 14.132 0 C 8.227 0 3.484 4.75 3.484 10.663 L 3.484 14.735 C 3.484 15.51 4.162 16.189 4.936 16.189 Z M 14.132 29.276 C 11.906 29.276 10.164 27.434 10.164 25.301 C 10.164 23.168 12.003 21.327 14.132 21.327 C 16.262 21.327 18.101 23.169 18.101 25.301 C 18.101 27.434 16.262 29.276 14.132 29.276 Z M 14.132 24.235 C 13.551 24.235 13.067 24.72 13.067 25.301 C 13.067 25.883 13.551 26.368 14.132 26.368 C 14.713 26.368 15.197 25.883 15.197 25.301 C 15.197 24.72 14.713 24.235 14.132 24.235 Z"
            fill="rgb(51,54,58)"
          ></path>
          <path
            d="M 14.132 34.607 C 13.358 34.607 12.68 33.929 12.68 33.153 L 12.68 27.821 C 12.68 27.046 13.358 26.367 14.132 26.367 C 14.906 26.367 15.584 27.046 15.584 27.821 L 15.584 33.153 C 15.584 34.026 14.906 34.607 14.132 34.607 Z"
            fill="rgb(51,54,58)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

function FilePaperIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="88" height="128">
      <path
        d="M 1.405 14.509 L 86.302 14.509 L 86.302 126.532 L 1.405 126.532 Z"
        fill="rgb(255,255,255)"
      ></path>
      <path
        d="M 86.402 14.509 L 86.402 126.431 L 1.405 126.431"
        fill="rgb(147,163,162)"
      ></path>
      <path
        d="M 86.402 127.948 L 1.505 127.948 C 0.702 127.948 0 127.24 0 126.431 L 0 14.509 C 0 13.7 0.702 12.992 1.505 12.992 L 86.402 12.992 C 87.205 12.992 87.908 13.7 87.908 14.509 L 87.908 126.532 C 87.908 127.24 87.205 127.948 86.402 127.948 Z M 2.91 124.915 L 84.797 124.915 L 84.797 16.025 L 2.91 16.025 Z"
        fill="rgb(51,54,58)"
      ></path>
      <path
        d="M 1.405 8.139 L 86.302 8.139 L 86.302 120.163 L 1.405 120.163 Z"
        fill="rgb(255,255,255)"
      ></path>
      <path
        d="M 86.402 8.139 L 86.402 120.163 L 1.405 120.163"
        fill="rgb(192,204,204)"
      ></path>
      <path
        d="M 86.402 121.679 L 1.505 121.679 C 0.702 121.679 0 120.972 0 120.163 L 0 8.139 C 0 7.33 0.702 6.622 1.505 6.622 L 86.402 6.622 C 87.205 6.622 87.908 7.33 87.908 8.139 L 87.908 120.163 C 87.908 120.972 87.205 121.679 86.402 121.679 Z M 2.91 118.646 L 84.797 118.646 L 84.797 9.656 L 2.91 9.656 Z"
        fill="rgb(51,54,58)"
      ></path>
      <path
        d="M 1.405 1.668 L 86.302 1.668 L 86.302 113.692 L 1.405 113.692 Z"
        fill="rgb(255,255,255)"
      ></path>
      <path
        d="M 86.402 1.668 L 86.402 113.591 L 1.405 113.591"
        fill="rgb(234,239,239)"
      ></path>
      <path
        d="M 69.644 22.799 L 18.063 22.799 C 17.26 22.799 16.558 22.091 16.558 21.283 C 16.558 20.474 17.26 19.766 18.063 19.766 L 69.644 19.766 C 70.447 19.766 71.149 20.474 71.149 21.283 C 71.149 22.091 70.547 22.799 69.644 22.799 Z"
        fill="rgb(167,180,188)"
      ></path>
      <path
        d="M 69.644 34.932 L 18.063 34.932 C 17.26 34.932 16.558 34.224 16.558 33.415 C 16.558 32.606 17.26 31.898 18.063 31.898 L 69.644 31.898 C 70.447 31.898 71.149 32.606 71.149 33.415 C 71.149 34.224 70.547 34.932 69.644 34.932 Z"
        fill="rgb(103,110,117)"
      ></path>
      <path
        d="M 69.644 47.064 L 18.063 47.064 C 17.26 47.064 16.558 46.356 16.558 45.548 C 16.558 44.739 17.26 44.031 18.063 44.031 L 69.644 44.031 C 70.447 44.031 71.149 44.739 71.149 45.548 C 71.149 46.356 70.547 47.064 69.644 47.064 Z"
        fill="rgb(167,180,188)"
      ></path>
      <path
        d="M 69.644 59.197 L 18.063 59.197 C 17.26 59.197 16.558 58.489 16.558 57.68 C 16.558 56.871 17.26 56.164 18.063 56.164 L 69.644 56.164 C 70.447 56.164 71.149 56.871 71.149 57.68 C 71.149 58.489 70.547 59.197 69.644 59.197 Z"
        fill="rgb(103,110,117)"
      ></path>
      <path
        d="M 69.644 71.329 L 18.063 71.329 C 17.26 71.329 16.558 70.621 16.558 69.813 C 16.558 69.004 17.26 68.296 18.063 68.296 L 69.644 68.296 C 70.447 68.296 71.149 69.004 71.149 69.813 C 71.149 70.621 70.547 71.329 69.644 71.329 Z"
        fill="rgb(167,180,188)"
      ></path>
      <path
        d="M 69.644 83.462 L 18.063 83.462 C 17.26 83.462 16.558 82.754 16.558 81.945 C 16.558 81.136 17.26 80.429 18.063 80.429 L 69.644 80.429 C 70.447 80.429 71.149 81.136 71.149 81.945 C 71.149 82.754 70.547 83.462 69.644 83.462 Z"
        fill="rgb(103,110,117)"
      ></path>
      <path
        d="M 69.644 95.594 L 18.063 95.594 C 17.26 95.594 16.558 94.887 16.558 94.078 C 16.558 93.269 17.26 92.561 18.063 92.561 L 69.644 92.561 C 70.447 92.561 71.149 93.269 71.149 94.078 C 71.149 94.887 70.547 95.594 69.644 95.594 Z"
        fill="rgb(167,180,188)"
      ></path>
      <path
        d="M 86.402 115.108 L 1.505 115.108 C 0.702 115.108 0 114.4 0 113.591 L 0 1.668 C 0 0.859 0.702 0.151 1.505 0.151 L 86.402 0.151 C 87.205 0.151 87.908 0.859 87.908 1.668 L 87.908 113.591 C 87.908 114.501 87.205 115.108 86.402 115.108 Z M 2.91 112.074 L 84.797 112.074 L 84.797 3.185 L 2.91 3.185 Z"
        fill="rgb(51,54,58)"
      ></path>
    </svg>
  );
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: any) => {
  return (
    <div>
        <FilePaperIcon />
        <LockBalloonIcon />
    </div>
  );
};
