import React, { useRef, useState, useEffect, useCallback } from 'react';
import { TableRow, TableCell, Icon, IconButton, Popover, CardContent, InputAdornment } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { Ingredient, ResourceType, ResourceUnion } from '../../types';
import { getVolume, getPercentageFine, getMoisture } from '../../computed/recipeComputations';
import { TableCellProps } from '@material-ui/core/TableCell';
import { OutlinedTextFieldProps } from '@material-ui/core/TextField';
import NumericTextField from '../NumericTextField';
import useAuthorized from '../../useAuthorized';

const format = {
  decimalSeparator: ',',
  thousandSeparator: '.',
  decimalScale: 1,
  displayType: 'text' as 'text'
}

export const EditTableCell: React.FC<TableCellProps & { onClose?: () => void, renderPopoverContent: (onClose: () => void) => JSX.Element }> = ({ children, renderPopoverContent, onClose, ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null as HTMLElement | null);
  const cellRef = useRef(null);
  const canEdit = useAuthorized(['update:recipes'])
  const handleClose = useCallback(() => {
    setAnchorEl(null);
    onClose && onClose();
  }, [onClose]);
  return <TableCell {...props} ref={cellRef}>
    {children}
    {canEdit && <IconButton style={{ margin: '-12px -12px -12px 0' }} onClick={() => setAnchorEl(cellRef.current)}><Icon fontSize="small">create</Icon></IconButton>}
    <Popover
      onEntered={(ref: HTMLElement) => ref.removeAttribute('tabindex')}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={() => handleClose()}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {renderPopoverContent(handleClose)}
    </Popover>
  </TableCell>
}

export const PopoverEditField: React.FC<Omit<OutlinedTextFieldProps, 'variant' | 'onChange'> & { onChange: (e: any) => void, onClose: () => void }> = ({ onClose, ...props }) => {
  const inputRef = useRef(null);
  useEffect(() => (inputRef.current as unknown as HTMLElement).focus(), []);
  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    if ([13 /* ENTER */, 9 /* TAB */].indexOf(e.keyCode) >= 0) {
      e.preventDefault();
      onClose();
    }
  }, [onClose]);
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, [handleKeyDown]);
  return (
    <CardContent>
      <NumericTextField
        inputRef={inputRef}
        InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
        maximumFractionDigits={3}
        {...props as any}
      />
    </CardContent>
  )
}

type TableEditRowProps = {
  ingredient: Ingredient,
  additionTotal: number,
  displayType: 'internal' | 'external',
  onChange: (ingredient: Ingredient) => void
}

function getResourceTypeProps(resourceType: ResourceType) {
  switch (resourceType) {
    case ResourceType.Addition: return ['absorption', 'moisture'];
    case ResourceType.Excipient: return ['absorption', 'moisture'];
    case ResourceType.Extra: return ['absorption', 'moisture'];
    default: return [];
  }
}

export const TableEditRow: React.FC<TableEditRowProps> = ({ ingredient, additionTotal, displayType, onChange }) => {
  const resourceTypeProps = getResourceTypeProps(ingredient.resource.type);
  const [moisture, setMoisture] = useState(ingredient.moisture || (resourceTypeProps.indexOf('moisture') && (ingredient.resource as ResourceUnion & { moisture?: number }).moisture ? (ingredient.resource as ResourceUnion & { moisture?: number }).moisture : ''));

  let absorption=(ingredient.absorption!==undefined && ingredient.absorption!==null) ? ingredient.absorption : 0;
  if(!absorption && resourceTypeProps.indexOf('absorption')>-1)
    absorption=(ingredient.resource as ResourceUnion & { absorption?: number }).absorption;
  if(absorption===null) absorption=0;

  return (
    <TableRow>
      <TableCell {...ingredient.resource.type !== ResourceType.Addition && { colSpan: 2 }}>{ingredient.resource.name}</TableCell>
      {ingredient.resource.type === ResourceType.Addition && <TableCell align="right" style={{ whiteSpace: 'nowrap' }}><NumberFormat value={100 / additionTotal * getVolume([ingredient])} {...{ ...format, decimalScale: 0 }} />%</TableCell>}
      {displayType === 'internal' && <TableCell align="right" style={{ whiteSpace: 'nowrap' }}><NumberFormat value={ingredient.amount} {...{ ...format, decimalScale: 2 }} /> kg</TableCell>}
      {displayType === 'internal' && <TableCell align="right" style={{ whiteSpace: 'nowrap' }}><NumberFormat value={getPercentageFine([ingredient])} {...format} /> L</TableCell>}
      {displayType === 'internal' && <EditTableCell
        onClose={() => onChange({ ...ingredient, moisture: moisture && moisture !== '' ? Number(moisture) : 0 })}
        align="right"
        style={{ whiteSpace: 'nowrap' }}
        renderPopoverContent={(onClose) => <PopoverEditField
          label="Vochtpercentage (incl. absorptie)"
          value={moisture}
          onChange={e => setMoisture(e.target.value)}
          onClose={onClose}
        />}
      >
        <NumberFormat value={ingredient.moisture || (resourceTypeProps.indexOf('moisture') && (ingredient.resource as ResourceUnion & { moisture?: number }).moisture ? (ingredient.resource as ResourceUnion & { moisture?: number }).moisture : 0)} {...format} decimalScale={3} />%
      </EditTableCell>}
      {/*
      {displayType === 'internal' && <EditTableCell
        onClose={() => onChange({ ...ingredient, absorption: absorption && absorption !== '' ? Number(absorption) : 0 })}
        align="right"
        style={{ whiteSpace: 'nowrap' }}
        renderPopoverContent={(onClose) => <PopoverEditField
          label="Absorptie"
          value={absorption || ''}
          onChange={e => setAbsorption(e.target.value)}
          onClose={onClose}
        />}
      >
        <NumberFormat value={ingredient.absorption || (resourceTypeProps.indexOf('absorption') && (ingredient.resource as ResourceUnion & { absorption?: number }).absorption ? (ingredient.resource as ResourceUnion & { absorption?: number }).absorption : 0)} {...format} decimalScale={3} />%
      </EditTableCell>}
      */}
      {displayType === 'internal' &&
        <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>
          <NumberFormat value={absorption} {...format} decimalScale={3} />%</TableCell>
      }

      {displayType === 'internal' && <TableCell align="right" style={{ whiteSpace: 'nowrap' }}><NumberFormat value={getMoisture([ingredient])} {...format} /> kg</TableCell>}
      {displayType === 'internal' && <TableCell align="right" style={{ whiteSpace: 'nowrap' }}><NumberFormat value={ingredient.amount + getMoisture([ingredient])} {...format} /> kg</TableCell>}
    </TableRow>
  );
}

export default TableEditRow;
