import React, { createContext, useState, useCallback, useEffect } from 'react';
import { Toolbar, Box } from '@material-ui/core';
import { User } from './types';
import { LOCAL_STORAGE_KEY } from '.';
import Loading from './components/Loading';
import AppToolbar from './components/AppToolbar';
import { Route, Redirect, Switch, withRouter, RouteComponentProps, useHistory } from 'react-router-dom';
import { Resources, Settings as SettingsComponent, Attests } from './containers';
import StoreContextProvider from './StoreContextProvider';
import jwt from 'jsonwebtoken'
import Unauthorized from './containers/Unauthorized';
import NotFound from './containers/NotFound'
import Recipes from './pages/Recipes'
import LoginForm from "./components/login";

type TAppContextValue = {
  setToken: (token: string) => void,
  logout: () => void
}

export const AppContext = createContext({} as TAppContextValue);

const App: React.FC<RouteComponentProps> = () => {
  const [loading, setLoading] = useState('Inloggen...' as string | undefined);
  const [token, setToken] = useState(undefined as string | undefined);
  const [user, setUser] = useState(undefined as User | undefined);

  const history = useHistory();

  useEffect(() => {
    const tokenData=getValidTokenData(localStorage.getItem(LOCAL_STORAGE_KEY))
    if(!tokenData)
      redirectLogin();
    else
      setUser({
        created_at: undefined,
        email: "",
        email_verified: false,
        last_ip: "",
        last_login: undefined,
        logins_count: 0,
        nickname: "",
        picture: "",
        updated_at: undefined,
        user_id: "",
        username: "",
        name: tokenData.username }
      );
    setLoading(undefined);
  },[token, history])

  function getValidTokenData(token: string) {
    const decodedToken=jwt.decode(token || '') as any

    if(decodedToken===null) return false; // No token found
    if(decodedToken.username===undefined) return false; // No user data found
    if(decodedToken.exp && Date.now() > decodedToken.exp * 1000) return false; // Token expired

    return decodedToken;
  }

  function redirectLogin() {
    const url = new URL(window.location as any)
    const href = url.origin + '/login'
    if (href !== window.location.href) {
      // Redirect to login page
      window.location.href = href
    }
    return;
  }

  const logout = useCallback(() => {
    localStorage.removeItem(LOCAL_STORAGE_KEY)
    const url = new URL(window.location as any)
    window.location.href = url.origin + '/logout'
  }, []);

  // @ts-ignore
  return loading ? <Loading text={loading} /> : (
    <AppContext.Provider value={{ setToken, logout }}>
      {/* @ts-ignore */}
      <Box display="flex" flexDirection="column" width="100%" height="100%">
        {/* @ts-ignore */}
        <Switch>
          {!user && (
            /* @ts-ignore */
            <Box flex={1} overflow="auto">
              {/* @ts-ignore */}
              <Switch>
                {/* @ts-ignore */}
                <Route path="/login" component={LoginForm} />
              </Switch>
            </Box>
          )}
        </Switch>
        {/* @ts-ignore */}
        <Switch>
          {user && (
            <StoreContextProvider>
              <AppToolbar user={user} />
              <Toolbar />
              {/* @ts-ignore */}
              <Box flex={1} overflow="auto">
                {/* @ts-ignore */}
                <Switch>
                  {/* @ts-ignore */}
                  <Route path="/recipe" component={Recipes} />
                  {/* @ts-ignore */}
                  <Route path="/resource/add/:resourceType?" component={Resources} />
                  {/* @ts-ignore */}
                  <Route path="/resource/:resourceId?" component={Resources} />
                  {/* @ts-ignore */}
                  <Route path="/attest/:attestId?" component={Attests} />
                  {/* @ts-ignore */}
                  <Route path="/settings" component={SettingsComponent} />
                  {/* @ts-ignore */}
                  <Route path="/unauthorized" component={Unauthorized} />
                  {/* @ts-ignore */}
                  <Route path="/" exact={true}><Redirect to="/recipe" /></Route>
                  {/* @ts-ignore */}
                  <Route component={NotFound} />
                </Switch>
              </Box>
            </StoreContextProvider>
          )}
        </Switch>
      </Box>
    </AppContext.Provider>
  );
}

export default withRouter(App);
