import addition from './icons/addition.svg';
import cement from './icons/cement.svg';
import water from './icons/water.svg';
import excipient from './icons/excipient.svg';
import extra from './icons/extra.svg';
import filler from './icons/filler.svg';
import { ResourceType } from './types';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [ResourceType.Addition]: {
    color: '#7c6d46',
    title: 'Toeslag',
    icon: addition
  },
  [ResourceType.Cement]: {
    color: '#4d5146',
    title: 'Cement',
    icon: cement
  },
  [ResourceType.Water]: {
    color: '#1e7cb3',
    title: 'Water',
    icon: water
  },
  [ResourceType.Excipient]: {
    color: '#490808',
    title: 'Hulpstof',
    icon: excipient
  },
  [ResourceType.Extra]: {
    color: '#000000',
    title: 'Extra',
    icon: extra
  },
  [ResourceType.Filler]: {
    color: '#000000',
    title: 'Vulstof',
    icon: filler
  }
};
