import React, { useContext, useCallback } from 'react';
import { Step } from './RecipeDialogContent';
import { StoreContext } from '../../StoreContextProvider';
import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import OptionButton from './OptionButton';
import { EnvironmentClass, ConsistencyClass, ResourceType, EnvironmentClassEncroachment } from '../../types';
import { getMaxWbf, getMinCement } from '../../computed/recipeComputations';
import encroachment from '../../encroachment'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(3)
  }
}));

const strengthClassStep: Step = {
  title: 'Kies een sterkteklasse',
  hasErrors: recipe => !Boolean(recipe.strengthClass),
  isCompleted: recipe => Boolean(recipe.strengthClass),
  optionalText: recipe => recipe.strengthClass ? recipe.strengthClass.code : undefined,
  StepComponent: ({ recipe, onChange }) => {
    const classes = useStyles();
    const { strengthClasses } = useContext(StoreContext);
    return (
      <Grid container={true} wrap="wrap" spacing={2} className={classes.container}>
        {strengthClasses.map((strengthClass, k) => (
          <OptionButton key={k} onClick={() => onChange({ ...recipe, strengthClass })} selected={recipe.strengthClass ? strengthClass.id === recipe.strengthClass.id : false}>{strengthClass.code}</OptionButton>
        ))}
      </Grid>
    )
  }
}

const environmentClassStep: Step = {
  title: 'Kies de milieuklasse(n)',
  hasErrors: recipe => recipe.environmentClasses ? recipe.environmentClasses.length === 0 : false,
  isCompleted: recipe => recipe.environmentClasses ? recipe.environmentClasses.length > 0 : false,
  optionalText: recipe => recipe.environmentClasses && recipe.environmentClasses.length > 0 ? recipe.environmentClasses.map(e => e.code).join(', ') : undefined,
  StepComponent: ({ recipe, onChange }) => {
    const classes = useStyles();
    const { environmentClasses } = useContext(StoreContext);
    const categories = environmentClasses.reduce((categories, e) => {
      const index = categories.map(c => c.encroachment).indexOf(e.encroachment);
      index >= 0 && categories[index].environmentClasses.push(e);
      return index >= 0 ? categories : [...categories, { encroachment: e.encroachment, environmentClasses: [e] }];
    }, [] as Array<{ encroachment: EnvironmentClassEncroachment, environmentClasses: EnvironmentClass[] }>);
    const handleSelect = useCallback((environmentClass: EnvironmentClass) => {
      const index = (recipe.environmentClasses ? recipe.environmentClasses : []).map(e => e.id).indexOf(environmentClass.id);
      const environmentClasses = index >= 0 && recipe.environmentClasses ? recipe.environmentClasses.filter(e => e.id !== environmentClass.id) : [...recipe.environmentClasses || [], environmentClass];
      const wbf = getMaxWbf(environmentClasses) - 0.02;
      const minCement = getMinCement(environmentClasses);
      const { ingredients } = recipe;
      const wIndex = ingredients.findIndex(r => r.resource.type === ResourceType.Water);
      wIndex >= 0 && (ingredients[wIndex].amount = minCement * wbf);
      onChange({ ...recipe, wbf, environmentClasses, ingredients });
    }, [recipe, onChange]);
    return (
      <Grid container={true} wrap="wrap" spacing={2} className={classes.container}>
        {categories.sort().map(({ environmentClasses, ...e }, k) => (
          <Grid item={true} key={k}>
            <Grid container={true} direction="column" style={{ padding: '0 16px' }}>
              <Typography variant="body2">{encroachment[e.encroachment].title}</Typography>
              <Grid container={true} direction="column" spacing={2} style={{ borderLeft: '1px solid #ccc', marginLeft: 8, marginTop: 8, marginBottom: 16, paddingLeft: 16 }}>
                {environmentClasses.sort((a, b) => (a.code > b.code && 1) || (a.code < b.code && -1) || 0).map((environmentClass, i) => (
                  <OptionButton key={i} onClick={() => handleSelect(environmentClass)} selected={(recipe.environmentClasses ? recipe.environmentClasses : []).map(e => e.id).indexOf(environmentClass.id) >= 0}>{environmentClass.code}</OptionButton>
                ))}
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    )
  }
}

const order = ['C', 'S', 'F', 'SF'];

export const consistencyClassStep: Step = {
  title: 'Kies een consistentieklasse',
  hasErrors: recipe => !Boolean(recipe.consistencyClass),
  isCompleted: recipe => Boolean(recipe.consistencyClass),
  optionalText: recipe => recipe.consistencyClass && (recipe.consistencyClass.code + ' ' + recipe.consistencyClass.description),
  StepComponent: ({ recipe, onChange }) => {
    const classes = useStyles();
    const { consistencyClasses } = useContext(StoreContext);
    const sort = useCallback((a: ConsistencyClass, b: ConsistencyClass) => {
      const aI = order.reduce((index, code, i) => a.code.indexOf(code) === 0 ? i : index, -1);
      const bI = order.reduce((index, code, i) => b.code.indexOf(code) === 0 ? i : index, -1);
      if (aI === bI) {
        return (a.code > b.code && 1) || (a.code < b.code && -1) || 0;
      } else {
        return aI - bI;
      }
    }, []);
    return (
      <Grid container={true} wrap="wrap" spacing={2} className={classes.container}>
        {consistencyClasses.sort(sort).map((consistencyClass, k) => (
          <OptionButton key={k} onClick={() => onChange({ ...recipe, consistencyClass })} selected={recipe.consistencyClass && consistencyClass.id === recipe.consistencyClass.id}><Typography variant="subtitle2">{consistencyClass.code}</Typography><Typography variant="body2">{consistencyClass.description}</Typography></OptionButton>
        ))}
      </Grid>
    )
  }
}

const typeOfWorkStep: Step = {
  title: 'Kies een soort werk',
  hasErrors: recipe => !Boolean(recipe.typeOfWork),
  isCompleted: recipe => Boolean(recipe.typeOfWork),
  optionalText: recipe => recipe.typeOfWork && recipe.typeOfWork.description ? recipe.typeOfWork.description : undefined,
  StepComponent: ({ recipe, onChange }) => {
    const classes = useStyles();
    const { typeOfWorks } = useContext(StoreContext);
    return (
      <Grid container={true} wrap="wrap" spacing={2} className={classes.container}>
        {typeOfWorks.map((typeOfWork, k) => (
          <OptionButton key={k} onClick={() => onChange({ ...recipe, typeOfWork })} selected={recipe.typeOfWork ? typeOfWork.id === recipe.typeOfWork.id : false}>{typeOfWork.description}</OptionButton>
        ))}
      </Grid>
    )
  }
}

const chlorideClassStep: Step = {
  title: 'Kies een chlorideklasse',
  hasErrors: recipe => !Boolean(recipe.chlorideClass),
  isCompleted: recipe => Boolean(recipe.chlorideClass),
  optionalText: recipe => recipe.chlorideClass ? recipe.chlorideClass.code : undefined,
  StepComponent: ({ recipe, onChange }) => {
    const classes = useStyles();
    const { chlorideClasses } = useContext(StoreContext);
    return (
      <Grid container={true} wrap="wrap" spacing={2} className={classes.container}>
        {chlorideClasses.sort((a, b) => a.maxChloride - b.maxChloride).map((chlorideClass, k) => (
          <OptionButton key={k} onClick={() => onChange({ ...recipe, chlorideClass })} selected={recipe.chlorideClass ? chlorideClass.id === recipe.chlorideClass.id : false}><Typography variant="subtitle2">{chlorideClass.code}</Typography><Typography variant="body2">{chlorideClass.description}</Typography></OptionButton>
        ))}
      </Grid>
    )
  }
}

export default [
  strengthClassStep,
  environmentClassStep,
  consistencyClassStep,
  typeOfWorkStep,
  chlorideClassStep
] as Step[]
