import * as React from "react";
import './noScrollXSVGAnimation.css';

const TruckIcon = function () {
  return (
    <svg
      style={{
        position: "absolute",
        left: 5.13,
        top: 91.62,
      }}
      xmlns="http://www.w3.org/2000/svg"
      width="189"
      height="108"
    >
      <g>
        <path
          d="M 31.23 87 C 34.284 79.919 42.198 76.295 49.555 78.608 C 56.911 80.922 61.326 88.424 59.777 95.978 C 58.229 103.532 51.218 108.692 43.545 107.925 C 35.872 107.157 30.022 100.711 30 93 C 30.002 90.937 30.42 88.897 31.23 87 Z"
          fill="rgb(132,132,132)"
        ></path>
        <path
          d="M 179.999 21 L 179.999 36 L 176.999 42 C 176.78 38.781 174.218 36.219 170.999 36 C 169.408 35.991 167.881 36.63 166.769 37.77 C 165.681 38.886 165.124 40.415 165.239 41.97 L 164.999 42 L 161.999 36 L 161.999 21 Z M 173.999 9 C 170.574 10.757 168.303 14.163 167.999 18 L 167.999 21 L 161.999 21 L 155.009 0 L 173.999 0 Z"
          fill="rgb(89,89,89)"
        ></path>
        <path
          d="M 164.999 69 L 170.999 84 L 158.999 84 L 155.999 78 L 78 78 L 75 84 L 63 84 L 69 69 L 146.999 69 Z"
          fill="rgb(255,187,5)"
        ></path>
        <path
          d="M 161.999 36 L 161.999 57 L 146.999 69 L 132 69 L 152.999 51 L 152.999 30 L 161.999 21 Z"
          fill="rgb(89,89,89)"
        ></path>
        <path
          d="M 152.999 30 L 152.999 51 L 132 69 L 105 69 L 105 66 L 104.85 65.46 C 113.924 64.215 122.512 60.604 129.75 54.99 C 133.267 52.298 136.441 49.185 139.199 45.72 Z"
          fill="rgb(247,247,247)"
        ></path>
        <path
          d="M 140.999 78 C 149.284 78 155.999 84.715 155.999 93 C 155.999 101.284 149.284 108 140.999 108 C 132.715 108 126 101.284 126 93 C 126 84.715 132.715 78 140.999 78 Z"
          fill="rgb(132,132,132)"
        ></path>
        <path
          d="M 93 78 L 140.999 78 C 132.715 78 126 84.715 126 93 C 126 101.284 132.715 108 140.999 108 L 93 108 C 101.284 108 108 101.284 108 93 C 108 84.715 101.284 78 93 78 Z"
          fill="rgb(247,247,247)"
        ></path>
        <path
          d="M 93 78 C 101.284 78 108 84.715 108 93 C 108 101.284 101.284 108 93 108 C 84.715 108 78 101.284 78 93 C 78 84.715 84.715 78 93 78 Z"
          fill="rgb(132,132,132)"
        ></path>
        <path
          d="M 78 78 L 93 78 C 84.715 78 78 84.715 78 93 C 78 101.284 84.715 108 93 108 L 45 108 C 52.489 107.989 58.822 102.456 59.839 95.036 C 60.855 87.617 56.244 80.584 49.034 78.559 C 41.824 76.533 34.225 80.136 31.23 87 L 21 87 L 23.37 82.26 C 27.427 74.129 35.734 68.994 44.82 69 L 69 69 L 63 84 L 75 84 Z"
          fill="rgb(247,247,247)"
        ></path>
        <path
          d="M 57 54 C 57 47.372 62.372 42 69 42 C 75.627 42 81 47.372 81 54 C 81 60.627 75.627 66 69 66 C 62.372 66 57 60.627 57 54 Z"
          fill="rgb(56,56,56)"
        ></path>
        <path
          d="M 48 21 L 48 69 L 44.82 69 C 35.734 68.994 27.427 74.129 23.37 82.26 L 21 87 L 0 87 L 0 45 L 36 45 L 36 15 L 42 15 C 45.309 15.01 47.99 17.69 48 21 Z"
          fill="rgb(217,217,217)"
        ></path>
        <path
          d="M 36 15 L 36 45 L 0 45 L 12 15 Z"
          fill="rgb(100,225,220)"
        ></path>
        <path
          d="M 60 54 C 60 49.029 64.029 45 69 45 C 73.97 45 78 49.029 78 54 C 78 58.97 73.97 63 69 63 C 64.029 63 60 58.97 60 54 Z"
          fill="rgb(255,187,5)"
        ></path>
        <path
          d="M 183.539 63 L 185.999 72 C 184.382 70.369 182.274 69.315 179.999 69 C 177.725 69.315 175.616 70.369 173.999 72 L 165.509 43.68 C 165.344 43.124 165.254 42.549 165.239 41.97 C 165.124 40.415 165.681 38.886 166.769 37.77 C 167.881 36.63 169.408 35.991 170.999 36 C 174.218 36.219 176.78 38.781 176.999 42 Z"
          fill="rgb(173,173,173)"
        ></path>
        <path
          d="M 185.999 72 L 185.999 74.67 C 186.071 77.682 183.972 80.312 181.019 80.91 C 179.276 81.211 177.489 80.726 176.135 79.587 C 174.782 78.448 174 76.769 173.999 75 L 173.999 72 C 175.616 70.369 177.725 69.315 179.999 69 C 182.274 69.315 184.382 70.369 185.999 72 Z"
          fill="rgb(89,89,89)"
        ></path>
        <path
          d="M 188.894 71.211 L 186.407 62.1 L 179.981 41.481 C 179.884 40.29 179.533 39.133 178.952 38.088 L 176.999 42 L 183.539 63 L 185.999 72 L 185.999 74.67 C 186.071 77.682 183.972 80.312 181.019 80.91 C 179.276 81.211 177.489 80.726 176.135 79.587 C 174.782 78.448 174 76.769 173.999 75 L 173.999 72 L 165.509 43.68 C 165.344 43.124 165.254 42.549 165.239 41.97 L 164.999 42 L 163.034 38.073 C 162.125 40.109 161.98 42.404 162.626 44.538 L 170.999 72.438 L 170.999 75 C 170.999 79.97 175.029 84 179.999 84 C 180.495 83.998 180.989 83.958 181.478 83.88 C 185.884 83.041 189.054 79.163 188.999 74.679 L 188.999 72 C 188.999 71.733 188.964 71.468 188.894 71.211 Z"
          fill="rgb(110,110,110)"
        ></path>
        <path
          d="M 167.999 21 L 161.999 21 L 161.999 27 L 167.999 27 C 171.313 27 173.999 29.686 173.999 33 L 173.999 36.981 C 175.756 38.066 176.875 39.938 176.999 42 L 179.999 36 L 179.999 21 Z"
          fill="rgb(110,110,110)"
        ></path>
        <path
          d="M 158.999 36 L 158.999 24 L 152.999 30 L 152.999 51 L 132 69 L 140.999 69 L 156.839 55.8 C 158.208 54.66 159 52.97 158.999 51.189 Z"
          fill="rgb(56,56,56)"
        ></path>
        <path
          d="M 47.82 75 L 66.6 75 L 69 69 L 44.82 69 C 35.734 68.994 27.427 74.129 23.37 82.26 L 21 87 L 27.114 87 C 31.374 79.586 39.269 75.011 47.82 75 Z"
          fill="rgb(89,89,89)"
        ></path>
        <path
          d="M 42 15 L 42 63 L 38.82 63 C 29.734 62.994 21.427 68.129 17.37 76.26 L 15 81 L 0 81 L 0 87 L 21 87 L 23.37 82.26 C 27.427 74.129 35.734 68.994 44.82 69 L 48 69 L 48 21 C 47.99 17.69 45.309 15.01 42 15 Z"
          fill="rgb(207,207,207)"
        ></path>
        <path
          d="M 36 21 L 36 15 L 12 15 L 0 45 L 8.4 45 L 18 21 Z"
          fill="rgb(180,245,240)"
        ></path>
        <path
          d="M 30 15 L 30 33 C 30 36.314 27.314 39 24 39 L 2.4 39 L 0 45 L 36 45 L 36 15 Z"
          fill="rgb(0,200,200)"
        ></path>
        <path
          d="M 161.999 21 L 167.999 21 L 167.999 18 C 168.303 14.163 170.574 10.757 173.999 9 L 158.006 9 Z"
          fill="rgb(56,56,56)"
        ></path>
        <path
          d="M 185.999 72 L 183.539 63 L 176.999 42 C 176.78 38.781 174.218 36.219 170.999 36 C 169.892 35.999 168.808 36.311 167.87 36.9 C 169.697 37.974 170.87 39.885 170.999 42 L 177.539 63 L 179.204 69.093 C 179.466 69.039 179.732 69.008 179.999 69 C 182.274 69.315 184.382 70.369 185.999 72 Z"
          fill="rgb(207,207,207)"
        ></path>
        <path
          d="M 185.999 72 C 184.382 70.369 182.274 69.315 179.999 69 C 177.725 69.315 175.616 70.369 173.999 72 L 173.999 75 C 175.616 73.369 177.725 72.315 179.999 72 C 182.265 72.304 184.365 73.351 185.972 74.976 C 185.972 74.874 185.999 74.772 185.999 74.676 Z"
          fill="rgb(56,56,56)"
        ></path>
        <path
          d="M 75 72 L 69 84 L 75 84 L 78 78 L 155.999 78 L 158.999 84 L 164.999 84 L 158.999 72 Z"
          fill="rgb(255,149,0)"
        ></path>
        <path
          d="M 132 93 C 132 88.029 136.029 84 140.999 84 C 145.97 84 149.999 88.029 149.999 93 C 149.999 97.97 145.97 102 140.999 102 C 136.029 102 132 97.97 132 93 Z"
          fill="rgb(89,89,89)"
        ></path>
        <path
          d="M 135 93 C 135 89.686 137.686 87 140.999 87 C 144.313 87 146.999 89.686 146.999 93 C 146.999 96.313 144.313 99 140.999 99 C 137.686 99 135 96.313 135 93 Z"
          fill="rgb(56,56,56)"
        ></path>
        <path
          d="M 84 93 C 84 88.029 88.029 84 93 84 C 97.97 84 102 88.029 102 93 C 102 97.97 97.97 102 93 102 C 88.029 102 84 97.97 84 93 Z"
          fill="rgb(89,89,89)"
        ></path>
        <path
          d="M 87 93 C 87 89.686 89.686 87 93 87 C 96.313 87 99 89.686 99 93 C 99 96.313 96.313 99 93 99 C 89.686 99 87 96.313 87 93 Z"
          fill="rgb(56,56,56)"
        ></path>
        <path
          d="M 36 93 C 36 88.029 40.029 84 45 84 C 49.97 84 54 88.029 54 93 C 54 97.97 49.97 102 45 102 C 40.029 102 36 97.97 36 93 Z"
          fill="rgb(89,89,89)"
        ></path>
        <path
          d="M 39 93 C 39 89.686 41.686 87 45 87 C 48.314 87 51 89.686 51 93 C 51 96.313 48.314 99 45 99 C 41.686 99 39 96.313 39 93 Z"
          fill="rgb(56,56,56)"
        ></path>
        <path
          d="M 63 54 C 63 50.686 65.686 48 69 48 C 72.313 48 75 50.686 75 54 C 75 57.314 72.313 60 69 60 C 65.686 60 63 57.314 63 54 Z"
          fill="rgb(89,89,89)"
        ></path>
        <path
          d="M 105 78 C 103.343 78 102 78 102 78 L 102 72 C 102 72 103.343 72 105 72 L 135 72 C 136.656 72 137.999 72 137.999 72 L 137.999 78 C 137.999 78 136.656 78 135 78 Z"
          fill="rgb(254,106,22)"
        ></path>
        <path
          d="M 143.999 78 C 142.343 78 140.999 78 140.999 78 L 140.999 72 C 140.999 72 142.343 72 143.999 72 L 149.999 72 C 151.656 72 152.999 72 152.999 72 L 152.999 78 C 152.999 78 151.656 78 149.999 78 Z"
          fill="rgb(254,106,22)"
        ></path>
      </g>
    </svg>
  );
};
// eslint-disable-next-line import/no-anonymous-default-export
export default (props: any) => {
  return (
      <TruckIcon />
  );
};
