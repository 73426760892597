import { EnvironmentClassEncroachment } from './types'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [EnvironmentClassEncroachment.Carbon]: {
    title: 'Corrosie ingeleid door carbonatie'
  },
  [EnvironmentClassEncroachment.Chemical]: {
    title: 'Chemische aantasting'
  },
  [EnvironmentClassEncroachment.Frost]: {
    title: 'Aantasting door vorst of dooi'
  },
  [EnvironmentClassEncroachment.Mechanical]: {
    title: 'Mechanische aantasting'
  },
  [EnvironmentClassEncroachment.None]: {
    title: 'Geen aantasting'
  },
  [EnvironmentClassEncroachment.Salt]: {
    title: 'Corrosie ingeleid door strooizout'
  },
  [EnvironmentClassEncroachment.Sea]: {
    title: 'Corrosie ingeleid door zeewater'
  }
};
