import axiosHttpClient from "../axiosHttpClient";

export function post(data: any) {
	return axiosHttpClient.post('/chloride-class', data);
}

export function getAll() {
	return axiosHttpClient.get('/chloride-classes');
}

export function getOne(id: number) {
	return axiosHttpClient.get('/chloride-class/one/'+id.toString());
}

export function deleteAllowed(id: number) {
	return axiosHttpClient.get('/chloride-class/delete-allowed/'+id.toString());
}

export function remove(id: number) {
	return axiosHttpClient.get('/chloride-class/delete/'+id.toString());
}

