import React, {ReactNode} from 'react';
import { Dialog, Slide, DialogContent, DialogActions, Button, DialogTitle } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions/transition';

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props: any, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type AddSettingDialogProps = {
  title: string
  open: boolean
  onClose: () => void
  onSave: () => void
  disableDialogContent?: boolean
  children?: ReactNode
}

const AddSettingDialog: React.FC<AddSettingDialogProps> = ({ title, children, open, onClose, onSave, disableDialogContent }) => {
  return (
    <Dialog open={open} TransitionComponent={Transition} onClose={onClose} onEntered={(ref: HTMLElement) => ref.removeAttribute('tabindex')}>
      <DialogTitle>{title}</DialogTitle>
      {disableDialogContent ? children : <DialogContent>
        {children}
      </DialogContent>}
      <DialogActions>
        <Button onClick={onClose}>Annuleren</Button>
        <Button color="primary" onClick={onSave}>Opslaan</Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddSettingDialog;
