import React, { Fragment, useState, useCallback } from 'react';
import {TextField, Grid, Snackbar} from '@material-ui/core';
import ConfirmDialog, { ConfirmDialogProps } from '../ConfirmDialog';
import AddSettingDialog from './AddSettingDialog';
import SettingsTable, { Column } from './SettingsTable';
import SettingsComponent from './SettingsComponent';
import NumberFormat from 'react-number-format';
import NumericTextField from '../NumericTextField';
import {deleteAllowed, getAll, getOne, post, remove } from "../../HTTPClients/RecipeApp/recipes/chloride-classes";
import CustomSnackbarContent from "../CustomSnackbarContent";

const columns: Column[] = [
  {
    name: 'code',
    title: 'Naam',
    getValue: (value: string) => <span style={{ whiteSpace: 'nowrap' }}>{value}</span>
  },
  {
    name: 'description',
    title: 'Beschrijving'
  },
  {
    name: 'maxChloride',
    title: 'Max. chloride gehalte',
    getValue: (value: number) => <NumberFormat displayType="text" value={value} thousandSeparator="." decimalSeparator="," />
  }
];

const ChlorideClasses: React.FC<{ PrevComponent: React.FC<{ SearchElement?: JSX.Element }> }> = ({ PrevComponent }) => {
  const [dialogProps, confirmDelete] = useState({ open: false } as Omit<ConfirmDialogProps, 'title' | 'content'>);
  const [filter, setFilter] = useState('');
  const [snackbar, setSnackbar] = useState(undefined as { message: string, variant: 'success' | 'warning' | 'error' | 'info' } | undefined);

  const getRows = useCallback(async () => {
    let response=await getAll();
    return response.data.data;
  },[]);

  const getRow = useCallback(async (chlorideClassId: string) => {
    let response=await getOne(Number(chlorideClassId));
    return response.data.data;
  },[]);

  const handleSave = useCallback(async (chlorideClass: any, chlorideClasses: any[] | undefined, saveCallback: (chlorideClasses?: any[]) => void) => {
    if (chlorideClasses && chlorideClass.id) {
      post(chlorideClass).then(
          function(response) {
            const index = chlorideClasses.findIndex(f => f.id === chlorideClass.id);
            index >= 0 && (chlorideClasses[index] = chlorideClass);
            saveCallback(chlorideClasses);
          }
      ).catch(
          function (error) {
            if(error.response===undefined)
              console.log(error);
            else
            if(error.response.data==='not_unique') notUniqueSnackbar();
          }
      )
    } else if (chlorideClasses) {
      post(chlorideClass).then(
          function(response) {
            chlorideClasses.push({ ...chlorideClass, id: response.data.data.id });
            saveCallback(chlorideClasses);
          }
      ).catch(
          function (error) {
            if(error.response===undefined)
              console.log(error);
            else
            if(error.response.data==='not_unique') notUniqueSnackbar();
          }
      )
    }
    saveCallback(chlorideClasses);
  },[]);

  function notUniqueSnackbar() {
    setSnackbar({
      variant: 'warning',
      message: 'Deze chlorideklasse bestaat al'
    })
  }

  const handleDelete = useCallback((chlorideClass: any, deleteCallback: (chlorideClass: any) => void) => {
    deleteAllowed(chlorideClass.id).then(
        function(response) {
          if(response.data.data) {
            confirmDelete({
              open: true,
              onCancel: () => confirmDelete({ open: false }),
              onConfirm: async () => {
                remove(chlorideClass.id).then(
                    function(response) {
                      deleteCallback(chlorideClass);
                    }
                ).finally(
                    function() { confirmDelete({ open: false }); }
                )
              }
            });
          } else {
            noDeleteSnackbar();
            return;
          }
        }
    )
  },[]);

    function noDeleteSnackbar() {
        setSnackbar({
            variant: 'warning',
            message: 'Deze chlorideklassemag niet verwijderd worden'
        })
    }

  const SearchElement = <TextField placeholder="Chlorideklasse zoeken..." value={filter} onChange={e => setFilter(e.target.value)} variant="outlined" margin="dense" />;

  return (
    <SettingsComponent
      PrevComponent={PrevComponent}
      SearchElement={SearchElement}
      path="/settings/chloride_classes"
      getRows={getRows}
      getRow={getRow}
    >{({ rows, row, addDialogOpen, onEdit, onSave, onChange, onDelete, onCancelAdd }) => (
      <Fragment>
        {rows && <SettingsTable
          columns={columns}
          rows={rows.filter(f => f.code.toLowerCase().indexOf(filter.toLowerCase()) >= 0).sort((a, b) => b.maxChloride - a.maxChloride)}
          onEdit={(chlorideClass: any) => chlorideClass.id && onEdit(chlorideClass.id)}
          onDelete={(chlorideClass: any) => handleDelete(chlorideClass, onDelete)}
        />}
        {!row ? null : (
          <AddSettingDialog
            title={row.id ? 'Chlorideklasse bewerken' : 'Chlorideklasse toevoegen'}
            open={addDialogOpen}
            onClose={() => onCancelAdd()}
            onSave={() => handleSave(row, rows, onSave)}
          >
            <Grid container={true} wrap="nowrap">
              <TextField
                label="Naam"
                value={row.code}
                onChange={e => onChange({ ...row, code: e.target.value })}
                variant="outlined"
                margin="normal"
                fullWidth={true}
                style={{ marginRight: 8 }}
              />
              <TextField
                label="Beschrijving"
                value={row.description}
                onChange={e => onChange({ ...row, description: e.target.value })}
                variant="outlined"
                margin="normal"
                fullWidth={true}
                style={{ marginLeft: 8 }}
              />
            </Grid>
            <NumericTextField
              label="Max. chloride gehalte"
              value={row.maxChloride}
              onChange={e => onChange({ ...row, maxChloride: e.target.value })}
              fullWidth={true}
              maximumFractionDigits={0}
            />
          </AddSettingDialog>
        )}
        <ConfirmDialog {...dialogProps} title="Chlorideklasse verwijderen" content="Weet u zeker dat u deze chlorideklasse wilt verwijderen?" />
        <Snackbar open={Boolean(snackbar)} onClose={() => setSnackbar(undefined)} autoHideDuration={6000}>
          <CustomSnackbarContent
              variant={snackbar ? snackbar.variant : undefined}
              message={snackbar ? snackbar.message : undefined}
          />
        </Snackbar>
      </Fragment>
    )}
    </SettingsComponent>
  )
}

export default ChlorideClasses;
