import axiosHttpClient from "./axiosHttpClient";

export function post(data: any) {
  if(data.minPercentageFine===null) data.minPercentageFine=0;
	return axiosHttpClient.post('/type-of-work', data);
}

export function remove(id: number) {
	return axiosHttpClient.get('/type-of-work/delete/'+id.toString());
}

export function getAll() {
	return axiosHttpClient.get('/types-of-work');
}

export function getOne(id: number) {
	return axiosHttpClient.get('/type-of-work/one/'+id.toString());
}

export function deleteAllowed(id: number) {
	return axiosHttpClient.get('/type-of-work/delete-allowed/'+id.toString());
}


