import React, { useState, useRef, useEffect, useCallback, Fragment } from 'react';
import { Ingredient, DosingMethod, ResourceType, Excipient } from '../../types';
import { DialogHeader } from '../Dialog';
import { DialogContent, InputAdornment, DialogActions, Button, Typography } from '@material-ui/core';
import NumericTextField from '../NumericTextField';

export const units = { [DosingMethod.Percent]: '%', [DosingMethod.Amount]: 'kg', [DosingMethod.Liter]: 'l' }
export const labels = { [DosingMethod.Percent]: 'Percentage', [DosingMethod.Amount]: 'Hoeveelheid', [DosingMethod.Liter]: 'Liters' }

type ChangeIngredientDialogContentProps = {
  ingredient: Ingredient,
  editPercentage: boolean,
  onChange: (ingredient: Ingredient) => void,
  onClose?: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void
  binderTotal?: number,
}

const ChangeIngredientDialogContent: React.FC<ChangeIngredientDialogContentProps> = ({ onClose, onChange, ingredient, editPercentage, binderTotal }) => {
  const [amount, setAmount] = useState(undefined as number | undefined);
  const [percentage, setPercentage] = useState(0 as number | undefined);
  const inputRef = useRef() as React.RefObject<HTMLInputElement>;

  useEffect(() => {
    if (ingredient && ingredient.amount && ingredient.resource.type === ResourceType.Excipient) {
      switch ((ingredient.resource as Excipient).dosingMethod) {
        case DosingMethod.Percent:
          setAmount(ingredient.amount * 100 / (binderTotal || 0))
          break
        case DosingMethod.Liter:
          setAmount(ingredient.amount / (ingredient.resource.density / 1000))
          break
        default: break
      }
    } else if (ingredient) {
      setAmount(ingredient.amount)
    }
    ingredient && setPercentage(ingredient.percentage || 0)
  }, [ingredient, binderTotal])

  useEffect(() => {
    setTimeout(() => inputRef.current && inputRef.current.focus(), 100);
  }, [inputRef]);

  const handleSubmit = useCallback((e: React.FormEvent) => {
    e.preventDefault();
    if (ingredient.resource.type === ResourceType.Excipient) {
      let calcAmount = amount || 0
      switch ((ingredient.resource as Excipient).dosingMethod) {
        case DosingMethod.Percent:
          calcAmount *= (binderTotal || 0) / 100
          break
        case DosingMethod.Liter:
          calcAmount *= ingredient.resource.density / 1000
          break
        default: break
      }
      onChange({ ...ingredient, amount: calcAmount })
    } else {
      editPercentage ? onChange({ ...ingredient, percentage }) : onChange({ ...ingredient, amount: amount || 0 });
    }
  }, [amount, percentage, ingredient, editPercentage, onChange, binderTotal]);

  return ingredient ? <form onSubmit={handleSubmit} noValidate={true}>
    <DialogHeader onClose={e => onClose && onClose(e, 'backdropClick')}>{ingredient.resource.name}</DialogHeader>
    <DialogContent>
      <NumericTextField
        label={ingredient.resource.type === ResourceType.Excipient ? labels[(ingredient.resource as Excipient).dosingMethod] : (editPercentage ? 'Percentage' : 'Hoeveelheid')}
        value={editPercentage ? percentage : amount}
        onChange={e => (editPercentage ? setPercentage(e.target.value) : setAmount(e.target.value))}
        onFocus={() => inputRef.current && inputRef.current.select()}
        InputProps={{
          endAdornment: <InputAdornment position="end">{ingredient.resource.type === ResourceType.Excipient ? units[(ingredient.resource as Excipient).dosingMethod] : (editPercentage ? '%' : 'Kg')}</InputAdornment>
        }}
        fullWidth={true}
        inputRef={inputRef}
        maximumFractionDigits={2}
      />
    </DialogContent>
    <DialogActions>
      <Button color="primary" type="submit">Opslaan&nbsp;&nbsp;<Typography variant="caption">(ENTER)</Typography></Button>
    </DialogActions>
  </form> : <Fragment />;
}

export default ChangeIngredientDialogContent;
