import React, { Fragment, useState, useCallback } from 'react';
import {TextField, MenuItem, Box, Snackbar} from '@material-ui/core';
import ConfirmDialog, { ConfirmDialogProps } from '../ConfirmDialog';
import AddSettingDialog from './AddSettingDialog';
import SettingsTable, { Column } from './SettingsTable';
import SettingsComponent from './SettingsComponent';
import NumberFormat from 'react-number-format';
import NumericTextField from '../NumericTextField';
import encroachment from '../../encroachment';
import { deleteAllowed, getAll, getOne, post, remove } from "../../HTTPClients/RecipeApp/recipes/environment-classes";
import CustomSnackbarContent from "../CustomSnackbarContent";
import {EnvironmentClassEncroachment} from "../../types";

// @ts-ignore
// @ts-ignore
const columns: Column[] = [
  {
    name: 'code',
    title: 'Code'
  },
  {
    name: 'encroachment',
    title: 'Aantasting',
    getValue: (value: EnvironmentClassEncroachment) => encroachment[value].title
  },
  {
    name: 'minCement',
    title: 'Min. Cement (kg)'
  },
  {
    name: 'maxWbf',
    title: 'Max. WBF',
    getValue: (value: number) => <NumberFormat displayType="text" value={value} thousandSeparator="." decimalSeparator="," />
  }
];

const order = ['X0', 'XC', 'XD', 'XS', 'XF', 'XA', 'XM'];

const EnvironmentClasses: React.FC<{ PrevComponent: React.FC<{ SearchElement?: JSX.Element }> }> = ({ PrevComponent }) => {
  const [dialogProps, confirmDelete] = useState({ open: false } as Omit<ConfirmDialogProps, 'title' | 'content'>);
  const [filter, setFilter] = useState('');
  const [snackbar, setSnackbar] = useState(undefined as { message: string, variant: 'success' | 'warning' | 'error' | 'info' } | undefined);

  const getRows = useCallback(async () => {
    let response=await getAll();
    return response.data.data;
  },[]);

  const getRow = useCallback(async (environmentClassId: string) => {
    let response=await getOne(Number(environmentClassId));
    return response.data.data;
  }, []);

  const handleSave = useCallback(async (environmentClass: any, environmentClasses: any[] | undefined, saveCallback: (environmentClasses?: any[]) => void) => {
    if (environmentClasses && environmentClass.id) {
      post(environmentClass).then(
          function(response) {
            const index = environmentClasses.findIndex(f => f.id === environmentClass.id);
            index >= 0 && (environmentClasses[index] = environmentClass);
            saveCallback(environmentClasses);
          }
      ).catch(
          function (error) {
            if(error.response===undefined)
              console.log(error);
            else
            if(error.response.data==='not_unique') notUniqueSnackbar();
          }
      )
    } else if (environmentClasses) {
      post(environmentClass).then(
        function(response) {
            environmentClasses.push({ ...environmentClass, id: response.data.data.id });
            saveCallback(environmentClasses);
          }
      ).catch(
          function (error) {
            if(error.response===undefined)
              console.log(error);
            else
            if(error.response.data==='not_unique') notUniqueSnackbar();
          }
      )
    }
  },[]);

  function notUniqueSnackbar() {
    setSnackbar({
      variant: 'warning',
      message: 'Deze milieuklasse bestaat al'
    })
  }

  const handleDelete = useCallback((environmentClass: any, deleteCallback: (environmentClass: any) => void) => {
    deleteAllowed(environmentClass.id).then(
        function(response) {
          if(response.data.data) {
            confirmDelete({
              open: true,
              onCancel: () => confirmDelete({ open: false }),
              onConfirm: async () => {
                remove(environmentClass.id).then(
                    function(response) {
                      deleteCallback(environmentClass);
                    }
                ).finally(
                    function() { confirmDelete({ open: false }); }
                )
              }
            });
          } else {
            noDeleteSnackbar();
            return;
          }
        }
    )
  },[]);

  function noDeleteSnackbar() {
    setSnackbar({
      variant: 'warning',
      message: 'Deze milieuklasse mag niet verwijderd worden'
    })
  }

  const sortRows = useCallback((a: any, b: any) => {
    const aIndex = order.findIndex(c => a.code.indexOf(c) === 0);
    const bIndex = order.findIndex(c => b.code.indexOf(c) === 0);
    return aIndex - bIndex;
  }, []);

  const SearchElement = <TextField placeholder="Milieuklasse zoeken..." value={filter} onChange={e => setFilter(e.target.value)} variant="outlined" margin="dense" />;

  return (
    <SettingsComponent
      PrevComponent={PrevComponent}
      SearchElement={SearchElement}
      path="/settings/environment_classes"
      getRows={getRows}
      getRow={getRow}
    >{({ rows, row, addDialogOpen, onEdit, onSave, onChange, onDelete, onCancelAdd }) => (
      <Fragment>
        {rows && <SettingsTable
          columns={columns}
          rows={rows.filter(f => f.code.toLowerCase().indexOf(filter.toLowerCase()) >= 0).sort((a, b) => ('' + a.code).localeCompare(b.code)).sort(sortRows)}
          onEdit={(environmentClass: any) => environmentClass.id && onEdit(environmentClass.id)}
          onDelete={(environmentClass: any) => handleDelete(environmentClass, onDelete)}
        />}
        {!row ? null : (
          <AddSettingDialog
            title={row.id ? 'Milieuklasse bewerken' : 'Milieuklasse toevoegen'}
            open={addDialogOpen}
            onClose={() => onCancelAdd()}
            onSave={() => handleSave(row, rows, onSave)}
          >
            <TextField
              label="Code"
              value={row.code}
              onChange={e => onChange({ ...row, code: e.target.value })}
              variant="outlined"
              margin="normal"
              fullWidth={true}
            />
            <TextField
              label="Aantasting"
              value={row.encroachment}
              select={true}
              onChange={e => onChange({ ...row, encroachment: e.target.value })}
              variant="outlined"
              margin="normal"
              fullWidth={true}
            >
              {Object.keys(encroachment).map(k => <MenuItem value={k}>{encroachment[k as EnvironmentClassEncroachment].title}</MenuItem>)}
            </TextField>
            <Box display="flex">
              <NumericTextField
                label="Min. Cement"
                value={row.minCement}
                onChange={e => onChange({ ...row, minCement: e.target.value })}
                margin="normal"
                fullWidth={true}
              />
              <span style={{ width: 32 }} />
              <NumericTextField
                label="Max. WBF"
                value={row.maxWbf}
                onChange={e => onChange({ ...row, maxWbf: e.target.value })}
                margin="normal"
                fullWidth={true}
              />
            </Box>
            <NumericTextField
              label="Max. eis"
              value={row.requirementMax}
              onChange={e => onChange({ ...row, requirementMax: e.target.value })}
              margin="normal"
              fullWidth={true}
            />
            <Box display="flex">
              <NumericTextField
                label="Max. afwijking"
                value={row.deviationMax}
                onChange={e => onChange({ ...row, deviationMax: e.target.value })}
                margin="normal"
                fullWidth={true}
              />
              <span style={{ width: 32 }} />
              <NumericTextField
                label="Max. aantal afwijkingen"
                value={row.maxDeviationsAllowed}
                onChange={e => onChange({ ...row, maxDeviationsAllowed: e.target.value })}
                margin="normal"
                fullWidth={true}
              />
            </Box>
          </AddSettingDialog>
        )}
        <ConfirmDialog {...dialogProps} title="Milieuklasse verwijderen" content="Weet u zeker dat u deze milieuklasse wilt verwijderen?" />
        <Snackbar open={Boolean(snackbar)} onClose={() => setSnackbar(undefined)} autoHideDuration={6000}>
          <CustomSnackbarContent
              variant={snackbar ? snackbar.variant : undefined}
              message={snackbar ? snackbar.message : undefined}
          />
        </Snackbar>
      </Fragment>
    )}
    </SettingsComponent>
  )
}

export default EnvironmentClasses;
