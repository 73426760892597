import React from 'react';

import { Grid, CircularProgress, Typography } from '@material-ui/core';

const Loading: React.FC<{text?: string}> = ({text}) => {
  return (
    <Grid style={{ position: 'absolute', top: '50%', left: '48%', zIndex: 1000, border: '1px solid #999', backgroundColor: '#fff', borderRadius: '.2rem', padding: '.25rem', boxShadow: '3px #aaa' }}>
      <CircularProgress variant="indeterminate" size={32} style={{marginLeft: '.3rem'}} />
      <Typography variant={'h6'} style={ {fontSize: '.75rem'} }>Laden...</Typography>
    </Grid>
  );
}

export default Loading;
