import React, { useState } from 'react';
import { Grid, ButtonBase, Card, CardContent, Divider, Icon, makeStyles } from '@material-ui/core';
import { green, grey } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  optionButton: {
    position: 'relative',
    background: green[600],
    color: theme.palette.getContrastText(green[600]),
  },
  optionButtonHover: {
    position: 'relative',
    background: grey[200],
    color: grey[600]
  },
  removeIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    color: grey[600]
  }
}));

type OptionEditButtonProps = {
  editContent: JSX.Element
  onClick: () => void
  onEdit: () => void
  showIcon?: boolean
}

const OptionEditButton: React.FC<OptionEditButtonProps> = ({ children, editContent, onClick, onEdit, showIcon }) => {
  const [hover, setHover] = useState(false);
  const { optionButton, optionButtonHover, removeIcon } = useStyles();
  return (
    <Grid item={true}>
      <Card className={hover ? optionButtonHover : optionButton}>
        <ButtonBase onClick={onClick} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
          <CardContent style={{ paddingBottom: 16, maxWidth: 180 }}>
            {children}
          </CardContent>
          {hover && <Icon fontSize="small" className={removeIcon}>clear</Icon>}
        </ButtonBase>
        <Divider />
        <ButtonBase onClick={onEdit} style={{ width: '100%', background: grey[200], color: grey[700] }}>
          <CardContent style={{ paddingTop: 8, paddingBottom: 8, width: '100%' }}>
            <Grid container={true} justifyContent="center" alignItems="center">
              {editContent}
              {showIcon ? (
                <Icon color="inherit" fontSize="small" style={{ marginLeft: 8 }}>create</Icon>
              ) : (<span></span>)}
            </Grid>
          </CardContent>
        </ButtonBase>
      </Card>
    </Grid>
  )
}

export default OptionEditButton;
