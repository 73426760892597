import React, { useState, useCallback } from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, IconButton, Icon, Menu, MenuItem, Typography, TableCellProps } from '@material-ui/core';

export type Column = {
  name: string,
  title: string,
  getValue?: (value: any) => JSX.Element | React.ReactText
} & TableCellProps

type SettingsTableProps = {
  columns: Column[]
  rows: any[]
  onEdit: (row: any) => void
  onDelete: (row: any) => void
}

const SettingsTable: React.FC<SettingsTableProps> = ({ columns, rows, onEdit, onDelete }) => {
  const [anchor, setAnchor] = useState(null as { el: Element, k: number } | null);
  const handleEdit = useCallback((row: any) => {
    setAnchor(null);
    onEdit(row);
  }, [onEdit]);
  const handleDelete = useCallback((row: any) => {
    setAnchor(null);
    onDelete(row);
  }, [onDelete]);
  return (
    <Table>
      <TableHead>
        <TableRow>
          {columns.map(({ name, title }) => (
            <TableCell key={name}>{title}</TableCell>
          ))}
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row, k) => (
          <TableRow key={k}>
            {columns.map(({ name, getValue, ...props }) => {
              const keys = name.split('.');
              const value = keys.reduce((value, key) => value[key], row);
              return (
                <TableCell key={name} {...props}>{getValue ? getValue(value) : value}</TableCell>
              );
            })}
            <TableCell padding="none" align="right">
              <IconButton onClick={e => setAnchor({ el: e.currentTarget, k })}>
                <Icon>more_vert</Icon>
              </IconButton>
              <Menu
                anchorEl={anchor && anchor.el}
                open={Boolean(anchor && anchor.k === k)}
                onClose={() => setAnchor(null)}
                onEntered={(ref: HTMLElement) => ref.removeAttribute('tabindex')}
              >
                <MenuItem onClick={() => handleEdit(row)}>
                  <Icon fontSize="small">create</Icon>&nbsp;
                    <Typography variant="body2">Bewerken</Typography>
                </MenuItem>
                <MenuItem onClick={() => handleDelete(row)}>
                  <Icon fontSize="small" color="error">delete</Icon>&nbsp;
                    <Typography variant="body2" color="error">Verwijderen</Typography>
                </MenuItem>
              </Menu>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default SettingsTable;
