import React from 'react';
import { TextField } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import NumericInput, { NumericInputProps } from 'react-numberinput-formatter';
import { InputBaseComponentProps } from '@material-ui/core/InputBase';

export type NumericTextFieldProps = Omit<TextFieldProps, 'variant' | 'onChange' | 'value'> & NumericInputProps

const NumericTextField: React.FC<NumericTextFieldProps> = ({ maximumFractionDigits, minimumFractionDigits, useGrouping, InputProps, onChange, ...props }) => {
  return (
    <TextField
      variant="outlined"
      onChange={onChange as any}
      {...props}
      InputProps={{
        ...InputProps,
        inputComponent: NumericInput as unknown as React.FC<InputBaseComponentProps>,
        inputProps: {
          maximumFractionDigits,
          minimumFractionDigits,
          useGrouping,
          pattern: '\\d*',
          ...InputProps ? InputProps.inputProps : {}
        }
      }}
    />
  )
}

export default NumericTextField;
