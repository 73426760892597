import axiosHttpClient from "./axiosHttpClient";

export function post(data: any) {
	return axiosHttpClient.post('/grading-curve', data);
}

export function getAll() {
	return axiosHttpClient.get('/grading-curves');
}

export function getOne(id: number) {
	return axiosHttpClient.get('/grading-curve/one/'+id.toString());
}

export function deleteAllowed(id: number) {
	return axiosHttpClient.get('/grading-curve/delete-allowed/'+id.toString());
}

export function remove(id: number) {
	return axiosHttpClient.get('/grading-curve/delete/'+id.toString());
}

