import axiosHttpClient from "../axiosHttpClient";

export function post(data: any) {
	return axiosHttpClient.post('/sieve-set', data);
}

export function getAll() {
	return axiosHttpClient.get('/sieve-sets');
}

export function getOne(id: number) {
	return axiosHttpClient.get('/sieve-set/one/'+id.toString());
}

export function deleteAllowed(id: number) {
	return axiosHttpClient.get('/sieve-set/delete-allowed/'+id.toString());
}

export function remove(id: number) {
	return axiosHttpClient.get('/sieve-set/delete/'+id.toString());
}

