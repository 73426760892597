import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { LinearProgress, Button } from '@material-ui/core';
import { Switch, Route, withRouter, RouteComponentProps } from 'react-router-dom';

type SettingsComponentProps = {
  PrevComponent: React.FC<{ SearchElement?: JSX.Element }>
  SearchElement: JSX.Element
  getRows: () => Promise<any[]>
  getRow: (rowId: string) => Promise<any>
  path: string
  children: (props: {
    addDialogOpen: boolean,
    onEdit: (rowId: string | number) => void,
    onChange: (row: any) => void,
    onSave: (rows?: any[]) => void,
    onDelete: (row: any) => void,
    onCancelAdd: () => void,
    rows?: any[],
    row?: any
  }) => JSX.Element
} & RouteComponentProps<{ rowId?: string }>

const SettingsComponent: React.FC<SettingsComponentProps> = ({ PrevComponent, SearchElement, getRows, getRow, path, match, location, history, children }) => {
  const [rows, setRows] = useState(undefined as any[] | undefined);
  const [row, setRow] = useState(undefined as any | undefined);
  const [loading, setLoading] = useState(false);
  const [addDialogOpen, setOpen] = useState(false);

  useEffect(() => {
    if (!loading && !rows) {
      (async () => {
        setLoading(true);
        const result = await getRows();
        setRows(result);
        setLoading(false);
      })();
    }
  }, [getRows, rows, loading]);

  useEffect(() => {
    if (!row && !loading && match.params.rowId) {
      if (match.params.rowId) {
        const found = rows && rows.find(r => r.id === Number(match.params.rowId));
        found && setRow(found);
        setOpen(true);
      }
    } else if (!row && match.path === path + '/add') {
      setRow({});
      setOpen(true);
    } else if (!match.params.rowId && match.path !== path + '/add') {
      setOpen(false);
      setRow(undefined);
    }
  }, [match, location, loading, row, rows, path, getRow]);

  const handleAdd = useCallback(() => {
    history.push(path + '/add' + location.search);
  }, [history, location, path]);

  const onEdit = useCallback((rowId: string | number) => {
    history.push(path + '/edit/' + rowId + location.search)
  }, [location, history, path]);

  const onChange = useCallback((row: any) => {
    setRow(row);
  }, []);

  const onSave = useCallback((rows?: any[]) => {
    setRows(rows);
    history.push(path + location.search);
  }, [history, location, path]);

  const onDelete = useCallback((row: any) => {
    rows && setRows(rows.filter(r => r !== row));
  }, [rows]);

  const onCancelAdd = useCallback(() => {
    history.push(path + location.search);
  }, [history, path, location]);

  const ExtendedSearchElement = (<Fragment>
    <Button variant="contained" color="primary" size="small" onClick={handleAdd} style={{ marginRight: 8 }}>Voeg toe</Button>
    {SearchElement}
  </Fragment>);

  return (
    <Fragment>
      <PrevComponent SearchElement={!loading ? ExtendedSearchElement : undefined} />
      {loading && <LinearProgress variant="indeterminate" />}
      {children({ addDialogOpen, onEdit, onChange, onSave, onDelete, onCancelAdd, rows, row })}
    </Fragment>
  )
}

const ComponentWithRouter = withRouter(SettingsComponent);

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: Omit<SettingsComponentProps, 'location' | 'match' | 'history'>) => (
  <Switch>
    <Route path={props.path + '/edit/:rowId'}><ComponentWithRouter {...props} /></Route>
    <Route path={props.path + '/add'}><ComponentWithRouter {...props} /></Route>
    <Route path={props.path}><ComponentWithRouter {...props} /></Route>
  </Switch>
);
