import React from 'react'
import ErrorView from '../views/ErrorView'
import { Typography, Button, Box } from '@material-ui/core'
import { useHistory } from 'react-router-dom';
import ConcreteTruckIconFrame from '../icons/ConcreteTruckIconFrame';


const iconStyle = { width: 200, height: 200 };
const errorViewProps = {
  title: 'Pagina niet gevonden',
  // icon: <ConcreteTruckIcon style={{ width: 200, height: 200 }} />,
  icon: <div style={iconStyle}>
    <ConcreteTruckIconFrame style={iconStyle} />
  </div>,
  statusCode: '404'
}

const NotFound: React.FC = () => {
  const history = useHistory()
  return (
    <ErrorView {...errorViewProps}>
      <Typography gutterBottom={true}>Waarom probeert u in plaats daarvan niet een van deze pagina's?</Typography>
      <Box display="flex" alignItems="center" marginTop={2}>
        <Button onClick={() => history.push('/recipe')} variant="contained" color="secondary" size="large">Recepten</Button>&nbsp;
        	<Button onClick={() => history.push('/resource')} variant="contained" color="secondary" size="large">Grondstoffen</Button>
      </Box>
    </ErrorView>
  )
}

export default NotFound
