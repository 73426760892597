import React, { useCallback } from 'react';
import { makeStyles, Theme, Paper, InputBase, Icon } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    position: 'relative'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  progress: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    background: 'none'
  }
}))

const SearchField: React.FC<{ placeholder: string, value?: string, onChange?: (value: string) => void }> = ({ placeholder, value, onChange }) => {
  const classes = useStyles();
  const handleChange = useCallback((value: string) => {
    onChange && onChange(value);
  }, [onChange]);
  return (
    <Paper className={classes.root} elevation={1}>
      <InputBase
        className={classes.input}
        placeholder={placeholder}
        value={value || ''}
        onChange={e => handleChange(e.target.value)}
      />
      <Icon>search</Icon>
    </Paper>
  )
}

export default SearchField;
