import React, { Fragment, useState, useCallback } from 'react'
import {Snackbar, TextField} from '@material-ui/core'
import ConfirmDialog, { ConfirmDialogProps } from '../ConfirmDialog'
import AddSettingDialog from './AddSettingDialog'
import SettingsTable from './SettingsTable'
import SettingsComponent from './SettingsComponent'
import { deleteAllowed, getAll, getOne, post, remove } from "../../HTTPClients/RecipeApp/recipes/families";
import CustomSnackbarContent from "../CustomSnackbarContent";

const Families: React.FC<{ PrevComponent: React.FC<{ SearchElement?: JSX.Element }> }> = ({ PrevComponent }) => {
  const [dialogProps, confirmDelete] = useState({ open: false } as Omit<ConfirmDialogProps, 'title' | 'content'>)
  const [filter, setFilter] = useState('')
  const [snackbar, setSnackbar] = useState(undefined as { message: string, variant: 'success' | 'warning' | 'error' | 'info' } | undefined);

  const getRows = useCallback(async () => {
    let response=await getAll();
    return response.data.data;
  },[])

  const getRow = useCallback(async (familyId: string) => {
    let response=await getOne(Number(familyId));
    return response.data.data;
  },[])

  const handleSave = useCallback(async (family: any, families: any[] | undefined, saveCallback: (families?: any[]) => void) => {
    if (families && family.id) {
      post(family).then(
          function(response) {
            const index = families.findIndex(f => f.id === family.id)
            index >= 0 && (families[index] = family)
            saveCallback(families);
          }
      ).catch(
          function (error) {
            if(error.response===undefined)
              console.log(error);
            else
            if(error.response.data==='not_unique') notUniqueSnackbar();
          }
      )
    } else if (families) {
      post(family).then(
          function(response) {
            families.push({ ...family, id: response.data.data.id })
            saveCallback(families);
          }
      ).catch(
          function (error) {
            if(error.response===undefined)
              console.log(error);
            else
            if(error.response.data==='not_unique') notUniqueSnackbar();
          }
      )
    }
  },[])

  function notUniqueSnackbar() {
    setSnackbar({
      variant: 'warning',
      message: 'Deze familie groep bestaat al'
    })
  }

  const handleDelete = useCallback((family: any, deleteCallback: (family: any) => void) => {
    deleteAllowed(family.id).then(
        function(response) {
          if(response.data.data) {
            confirmDelete({
              open: true,
              onCancel: () => confirmDelete({ open: false }),
              onConfirm: async () => {
                remove(family.id).then(
                    function(response) {
                      deleteCallback(family);
                    }
                ).finally(
                    function() { confirmDelete({ open: false }); }
                )
              }
            });
          } else {
            noDeleteSnackbar();
            return;
          }
        }
    )
  },[])

  function noDeleteSnackbar() {
    setSnackbar({
      variant: 'warning',
      message: 'Deze familie groep mag niet verwijderd worden'
    })
  }

  const SearchElement = <TextField placeholder="Familie groep zoeken..." value={filter} onChange={e => setFilter(e.target.value)} variant="outlined" margin="dense" />

  return (
    <SettingsComponent
      PrevComponent={PrevComponent}
      SearchElement={SearchElement}
      path="/settings/families"
      getRows={getRows}
      getRow={getRow}
    >{({ rows, row, addDialogOpen, onEdit, onSave, onChange, onDelete, onCancelAdd }) => (
      <Fragment>
        {rows && <SettingsTable
          columns={[{ name: 'name', title: 'Familie groep' }]}
          rows={rows.filter(f => f.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0)}
          onEdit={(family: any) => family.id && onEdit(family.id)}
          onDelete={(family: any) => handleDelete(family, onDelete)}
        />}
        {!row ? null : (
          <AddSettingDialog
            title={row.id ? 'Familie groep bewerken' : 'Familie groep toevoegen'}
            open={addDialogOpen}
            onClose={() => onCancelAdd()}
            onSave={() => handleSave(row, rows, onSave)}
          >
            <TextField
              label="Familie groep naam"
              value={row.name}
              onChange={e => onChange({ ...row, name: e.target.value })}
              variant="outlined"
              margin="normal"
              fullWidth={true}
            />
          </AddSettingDialog>
        )}
        <ConfirmDialog {...dialogProps} title="Familie groep verwijderen" content="Weet u zeker dat u deze familie groep wilt verwijderen?" />
        <Snackbar open={Boolean(snackbar)} onClose={() => setSnackbar(undefined)} autoHideDuration={6000}>
          <CustomSnackbarContent
              variant={snackbar ? snackbar.variant : undefined}
              message={snackbar ? snackbar.message : undefined}
          />
        </Snackbar>
      </Fragment>
    )}
    </SettingsComponent>
  )
}

export default Families
