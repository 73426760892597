import React, {Fragment, useCallback, useEffect, useState} from 'react'
import {Recipe, RecipeStatus} from '../../../types'
import {
  AppBar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControlLabel,
  Icon,
  Link,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography
} from '@material-ui/core'
import {green, red} from '@material-ui/core/colors'
import {format} from 'react-numberinput-formatter'

type Diff = { diff: any, oldValues: any, recipe: Recipe, errors: string[], ignoreErrors?: boolean, oldStatus: RecipeStatus }

const useStyles = makeStyles({
  content: {
    display: 'flex',
    alignItems: 'center'
  }
})

function getLabel(key: string) {
  switch (key) {
    case 'maxChloride': return 'Maximum chloride gehalte (%)'
    case 'minPercentageFine': return 'Minimum gehalte fijn (L)'
  }
}

function getPublishedState(status) {
  if(status===RecipeStatus.Published)
    return 'Gepubliceerd';
  else
    return 'Niet gepubliceerd';
}

export type RecipeChangesDialogProps = {
  open: boolean
  diffs?: Diff[]
  onPrevious?: () => void
  onCancel?: () => void
  onSave?: (recipes: Recipe[]) => Promise<void>
  resetSaving?: boolean
}

const RecipeChangesDialog: React.FC<RecipeChangesDialogProps> = ({ open, diffs, onCancel, onPrevious, onSave, resetSaving }) => {
  const [_diffs, setDiffs] = useState([] as Diff[])
  const [saving, setSaving] = useState(false)
  const classes = useStyles()

  useEffect(() => {
    if(resetSaving) setSaving(false);
    diffs && setDiffs(diffs)
  }, [diffs, resetSaving])

  const handleTogglePublish = useCallback((index: number, published: boolean) => {
    _diffs[index].recipe.status = published ? RecipeStatus.Unpublished : RecipeStatus.Published
    setDiffs([..._diffs])
  }, [_diffs])

  const handleToggleIgnore = useCallback((index: number, ignoreErrors: boolean) => {
    _diffs[index].recipe.status = !ignoreErrors ? RecipeStatus.Unpublished : RecipeStatus.Error
    _diffs[index].ignoreErrors = !ignoreErrors
    setDiffs([..._diffs])
  }, [_diffs])

  const handleToggleAll = useCallback(() => {
    let isAllToggled = true
    const filtered = _diffs.filter(({ recipe }) => recipe.status !== RecipeStatus.Error)
    filtered.some(({ recipe }) => {
      if (recipe.status !== RecipeStatus.Published) {
        isAllToggled = false
        return true
      }
      return false
    })
    _diffs.forEach(({ recipe }, i) => {
      if (recipe.status !== RecipeStatus.Error) {
        _diffs[i].recipe.status = isAllToggled ? RecipeStatus.Unpublished : RecipeStatus.Published
      }
    })
    setDiffs([..._diffs])
  }, [_diffs])

  const handleRepublish = useCallback(() => {
    _diffs.forEach(({ recipe }, i) => {
      if(_diffs[i].oldStatus === RecipeStatus.Published)
        _diffs[i].recipe.status = RecipeStatus.Published
        else
        _diffs[i].recipe.status = RecipeStatus.Unpublished
    })
    setDiffs([..._diffs])
  }, [_diffs])

  const handleSave = useCallback(async () => {
    setSaving(true)
    if (onSave && _diffs) {
      onSave(_diffs.map(({ recipe }) => recipe))
    }
  }, [onSave, _diffs])

  return !_diffs || !onCancel || !onPrevious || !onSave ? null : (
    <Dialog fullScreen={true} open={open} onEntered={(ref: HTMLElement) => ref.removeAttribute('tabindex')}>
      <AppBar>
        <Toolbar>
          <Typography variant="h6">Werksoort bewerken - Wijzigingen in recepten</Typography>
          <span style={{ flex: 1 }} />
          <Button color="inherit" onClick={() => onPrevious()}>Terug</Button>
        </Toolbar>
      </AppBar>
      <Toolbar />
      {saving ? <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" flex={1}><CircularProgress size={120} style={{ marginBottom: 8 }} /><Typography>Bezig met recepten opslaan...</Typography></Box> : <Fragment>
        <DialogContent style={{ flex: 'none' }}>
          <Typography gutterBottom={true} variant="body1">De ingevoerde wijzigingen hebben effect op de berekende waardes van de recepten.<br />Controlleer eerst de veranderde waardes voordat u de grondstof opslaat.</Typography>
          <Link onClick={() => onPrevious()} style={{ cursor: 'pointer' }}>Terug naar werksoort wijzigen</Link>
          <Box display="flex" alignItems="center" marginTop={2}>
            <Typography gutterBottom={true} variant="h6">Effect op recepten ({_diffs.length})</Typography>
            <span style={{ flex: 1 }} />
            <Link style={{ cursor: 'pointer' }} onClick={handleRepublish}>Gepubliceerde recepten opnieuw publiceren</Link>
            &nbsp;&nbsp;&nbsp;
            <Link style={{ cursor: 'pointer' }} onClick={handleToggleAll}>Alles (de)publiceren</Link>
          </Box>
        </DialogContent>
        {_diffs.sort((a, b) => a.recipe.id && b.recipe.id ? (a.recipe.id - b.recipe.id) : 0).map(({ diff, oldValues, recipe, errors, ignoreErrors }, k) => {
          if(!_diffs.length) setSaving(false);
          const hasError = recipe.status === RecipeStatus.Error
          const posNeg = Object.keys(diff).reduce((pn, k) => {
            if (oldValues[k] < diff[k]) pn.p++
            if (oldValues[k] > diff[k]) pn.n++
            return pn
          }, ({ p: 0, n: 0 }))
          return (
            <ExpansionPanel key={k}>
              <ExpansionPanelSummary expandIcon={<Icon>expand_more</Icon>} classes={classes}>
                <Typography variant="subtitle2" color={hasError ? 'error' : 'initial'}>
                  #{recipe.id} {recipe.recipeName}
                </Typography>
                {_diffs[k].oldStatus!==undefined &&
                    <span>&nbsp;&nbsp;({getPublishedState(_diffs[k].oldStatus)})</span>
                }
                {hasError && <Icon color="error" fontSize="small">warning</Icon>}
                <span style={{ flex: 1 }} />
                {posNeg.p > 0 && <Typography variant="body2" style={{ color: green[700] }}>+{posNeg.p}</Typography>}
                {posNeg.p > 0 && posNeg.n > 0 && <span>&nbsp;</span>}
                {posNeg.n > 0 && <Typography variant="body2" style={{ color: red[700], marginRight: 16 }}>-{posNeg.n}</Typography>}
                <FormControlLabel
                  control={<Checkbox checked={recipe.status === RecipeStatus.Published || false} onClick={e => e.stopPropagation()} onChange={e => handleTogglePublish(k, recipe.status === RecipeStatus.Published || false)} />}
                  label="Publiceren na opslaan"
                  disabled={hasError}
                />
              </ExpansionPanelSummary>
              <ExpansionPanelDetails style={{ flexDirection: 'column' }}>
                {errors.length > 0 && <FormControlLabel
                  control={<Checkbox checked={ignoreErrors || false} onChange={e => handleToggleIgnore(k, ignoreErrors || false)} />}
                  label="Fouten negeren"
                />}
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Berekening</TableCell>
                      <TableCell>Oude waarde</TableCell>
                      <TableCell>Nieuwe waarde</TableCell>
                      <TableCell>Verschil</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(diff).map(k => {
                      const change = Math.round((diff[k] - oldValues[k]) * 1000) / 1000
                      return (
                        <TableRow key={k}>
                          <TableCell>{getLabel(k)}</TableCell>
                          <TableCell>{format(oldValues[k])}</TableCell>
                          <TableCell style={{ color: errors.indexOf(k) >= 0 ? red[500] : undefined }}><span style={{ display: 'flex', alignItems: 'center' }}>{format(diff[k])} {errors.indexOf(k) >= 0 && <Icon color="error" fontSize="small">warning</Icon>}</span></TableCell>
                          <TableCell style={{ color: change > 0 ? green[700] : red[700] }}>{change > 0 ? '+' : ''}{format(change)}</TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          )
        })}
        <DialogActions style={{ justifyContent: 'flex-start' }}>
          <Button onClick={onCancel} color="secondary">Annuleren</Button>
          <Button onClick={handleSave} disabled={saving} color="primary">Opslaan</Button>
        </DialogActions>
      </Fragment>}
    </Dialog>
  )
}

export default RecipeChangesDialog
