import React from 'react';
import { SvgIcon, SvgIconProps } from "@material-ui/core";

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: SvgIconProps) => <SvgIcon {...props} viewBox="0 0 64 64">
  <g id="Outline">
    <polygon points="10 13.586 8.707 12.293 7.293 13.707 10 16.414 14.707 11.707 13.293 10.293 10 13.586" />
    <rect x="18" y="10" width="15" height="2" />
    <rect x="18" y="14" width="15" height="2" />
    <rect x="18" y="34" width="18" height="2" />
    <rect x="18" y="38" width="18" height="2" />
    <polygon points="13.293 33.293 11 35.586 8.707 33.293 7.293 34.707 9.586 37 7.293 39.293 8.707 40.707 11 38.414 13.293 40.707 14.707 39.293 12.414 37 14.707 34.707 13.293 33.293" />
    <rect x="18" y="46" width="17" height="2" />
    <rect x="18" y="50" width="17" height="2" />
    <polygon points="13.293 45.293 11 47.586 8.707 45.293 7.293 46.707 9.586 49 7.293 51.293 8.707 52.707 11 50.414 13.293 52.707 14.707 51.293 12.414 49 14.707 46.707 13.293 45.293" />
    <polygon points="10 25.586 8.707 24.293 7.293 25.707 10 28.414 14.707 23.707 13.293 22.293 10 25.586" />
    <rect x="18" y="22" width="22" height="2" />
    <rect x="18" y="26" width="22" height="2" />
    <path d="M53,36H48v-1.3A7,7,0,1,0,50,21H48V12.586L37.414,2H2V60H38v2H62V45A9.01,9.01,0,0,0,53,36Zm-3,4.764L48.618,38h2.764Zm1.935,12.887L50,55.586l-1.935-1.935L50,41.077ZM50,33a5.006,5.006,0,0,1-5-5V27h3a9.048,9.048,0,0,1,6.617,2.911A5.006,5.006,0,0,1,50,33Zm4.948-5.516A11.051,11.051,0,0,0,48,25H45V23h5A4.989,4.989,0,0,1,54.948,27.484ZM38,5.414,44.586,12H38ZM4,58V4H36V14H46v7H43v7a6.992,6.992,0,0,0,3,5.736v2.323A9.01,9.01,0,0,0,38,45V58Zm56,2H40V45a7.006,7.006,0,0,1,6.4-6.969l1.566,3.132L45.935,54.349,50,58.414l4.065-4.065L52.037,41.163,53.6,38.031A7.006,7.006,0,0,1,60,45Z" />
  </g>
</SvgIcon>
