import React, { Fragment } from 'react';
import { Card, CardContent, Grid, Typography, makeStyles, Box } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  card: {
    background: grey[100],
    border: `1px solid ${grey[300]}`,
    width: '100%',
    display: 'flex',
    minHeight: 106
  },
  container: {
    borderLeft: `1px solid ${theme.palette.divider}`,
    flex: 1,
    '&:first-child': {
      border: 'none'
    }
  }
}));

const Placeholder: React.FC = ({ children }) => (
  <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center"><Typography>{children}</Typography></Box>
)

export type OptionButtonsGroup = {
  options: any[]
  getOptionContent: (option: any) => React.ReactText | JSX.Element
  loadingText?: string
  noOptionsText?: string
  content?: React.ReactText | JSX.Element
  width?: number
}

type OptionButtonsContainerProps = {
  loading: boolean
  groups: OptionButtonsGroup[]
}

const OptionButtonsContainer: React.FC<OptionButtonsContainerProps> = ({ loading, groups }) => {
  const classes = useStyles();
  return (
    <Card className={classes.card} elevation={0}>
      {groups.map(({ options, loadingText, noOptionsText, getOptionContent, content, width }, k) => (
        <CardContent key={k} className={classes.container} {...width && { style: { width: 50 + '%', flex: 'none' } }}>
          {loading ? <Placeholder>{loadingText || 'Laden...'}</Placeholder> :
            options.length === 0 ? <Placeholder>{noOptionsText || 'Geen opties'}</Placeholder> :
              <Grid container={true} spacing={2}>
                {options.map((option, i) => <Fragment key={i}>{getOptionContent(option)}</Fragment>)}
              </Grid>}
          {content}
        </CardContent>
      ))}
    </Card>
  )
}

export default OptionButtonsContainer;
