import React, {CSSProperties} from 'react';
import {makeStyles, TextField, Typography} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import {Ingredient, Addition} from '../../types';

const useStyles = makeStyles({
  sliderItem: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    padding: 16,
    paddingLeft: 24,
    height: '100px'
  }
});

type AdditionDetailsProps = {
  k: number
  showInput: boolean
  addition: Ingredient & { resource: Addition }
  percentage: number
  showPercentage?: boolean
  onChange: (k:number, percentage: number) => void,
  style?: CSSProperties
}

const AdditionDetails: React.FC<AdditionDetailsProps> = ({ k, showInput, addition, percentage, showPercentage, onChange, style }) => {
  const { sliderItem } = useStyles();
  return (
    <div className={sliderItem} style={{ width: percentage + '%', flexWrap: 'wrap', marginTop: -16, ...style }}>
      {showInput && percentage<100 &&
          <div>
            <TextField label={"%"} type={"number"} size={'small'} className={'slider-perc-input'} variant={'outlined'}
                aria-valuemax={100} required={true} value={percentage}
                onChange={e=> {
                  onChange(k,isNaN(parseInt(e.target.value)) ? percentage : parseInt(e.target.value))
                }} />
        </div>
      }
      {!showInput && showPercentage &&
        <Typography align="right" color="primary" style={{flexGrow: 1}}>{percentage} %</Typography>
      }
      <Typography className={'slider-perc-label'}>
        <NumberFormat value={addition.amount} displayType="text" decimalSeparator="," thousandSeparator="." decimalScale={1} /> kg</Typography>
    </div>
  )
}

/*


 */

export default AdditionDetails;
