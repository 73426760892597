import React, { useState, useCallback } from 'react'
import { Box, Typography, Link, Breadcrumbs, Icon, Card } from '@material-ui/core'
import { indigo } from '@material-ui/core/colors'
import { useHistory, useLocation } from 'react-router-dom'

type Breadcrumb = {
  title: string
  link: string
}

type BreadcrumbsContextValue = {
  createBreadcrumbs: (breadcrumbs: Breadcrumb[]) => void
  setToolbarButton: (button: React.ReactNode) => void
}

export const BreadcrumbsContext = React.createContext({} as unknown as BreadcrumbsContextValue)

type Props = {
  children?: React.ReactNode
};

const PageWithBreadcrumbs: React.FC = ({ children }:Props) => {
  const history = useHistory()
  const { state } = useLocation()
  const [title, setTitle] = useState('')
  const [ToolbarButton, setToolbarButton] = useState(null as React.ReactNode)
  const [breadcrumbs, setBreadcrumbs] = useState([] as Breadcrumb[])
  const createBreadcrumbs = useCallback((breadcrumbs: Breadcrumb[]) => {
    setBreadcrumbs([...breadcrumbs])
    if (breadcrumbs.length > 0) {
      const { title } = breadcrumbs.pop() as Breadcrumb
      setTitle(title)
    } else {
      setTitle('')
    }
  }, [])
  return <BreadcrumbsContext.Provider value={{ createBreadcrumbs, setToolbarButton }}>
    <Box position="absolute" display="block" width="100%" bgcolor={indigo[50]} height={280} zIndex={1} />
    <Box padding={0} display="flex" flexDirection="column" height="100%">
      {breadcrumbs.length > 0 && <Box marginBottom={2} zIndex={2} display="flex" alignItems="center">
        <Box flex={1} paddingLeft={3} paddingTop={1}>
          <Typography variant="h6">{title}</Typography>
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/"><Icon fontSize="small" style={{ marginBottom: -4 }}>home</Icon></Link>
            {breadcrumbs.map(({ title, link }, k) => <Link key={k} style={{ cursor: 'pointer', textDecoration: 'none' }} onClick={() => history.push(link, state)}><Typography color={breadcrumbs.length - 1 === k ? 'primary' : 'textSecondary'}>{title}</Typography></Link>)}
          </Breadcrumbs>
        </Box>
        <Box>{ToolbarButton}</Box>
      </Box>}
      <Card style={{ flex: 1, zIndex: 2, overflow: 'auto' }}>
        {children}
      </Card>
    </Box>
  </BreadcrumbsContext.Provider>
}

export default PageWithBreadcrumbs
