import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'typeface-roboto';
import 'material-design-icons/iconfont/material-icons.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import moment from 'moment';
import 'moment/locale/nl';
import { createTheme } from '@material-ui/core/styles';
import { lightBlue } from '@material-ui/core/colors';
import { ThemeProvider } from '@material-ui/core';
import { CssBaseline } from '@material-ui/core';
import { BrowserRouter as Router } from 'react-router-dom';
import { setLocale } from 'react-numberinput-formatter';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

moment.locale('nl');
setLocale('nl')

export const LOCAL_STORAGE_KEY = 'token';

const theme = createTheme({
  palette: {
    secondary: {
      main: lightBlue.A700
    }
  }
})

ReactDOM.render(
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <CssBaseline />
        {/* @ts-ignore */}
        <Router basename={''}><App /></Router>
      </MuiPickersUtilsProvider>
    </ThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
